import React, { useState, useEffect } from "react";
import "../css/main.css";
import "../css/login.css";
import "../css/logincustom.css";
import "../css/structure.css";
import { Link, useNavigate } from "react-router-dom";

import { forgotPasswordURL } from "../globalURL";

import { toast } from "react-toastify";
import callPostApi from "../services/api";
const Forgotpwd = () => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const navigate = useNavigate();

  const submitHandler = async (event) => {
    if (email === "") {
      setEmailError("Please enter email");
    } else {
      try {
        const response = await callPostApi(forgotPasswordURL, { email: email });
        const detail = response.data;
        if (response.data.status === "valid") {
          toast.success(`${response.data.message}`);
          navigate("/login");
        } else {
          toast.error(`${response.data.message}`);
        }
      } catch (err) {
        setEmailError("Invalid data provided. Try Again..!");
      }
    }
  };

  return (
    <div>
      <div className="login-one">
        <div className="container-fluid login-one-container">
          <div className="p-30 mt-30">
            <div className="row main-login-one justify-content-center">
              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 p-0">
                <div className="log-bg">
                  <div className="login-one-start">
                    <div className="text-center py-3">
                      {" "}
                      <img src="assets/images/logo.png" alt="" height="70" />
                    </div>
                    <h6
                      style={{ color: "white" }}
                      className="mt-2 p-color text-center font-20"
                    >
                      Forgot Password?
                    </h6>
                    <p className="text-center p-color mt-2 mb-2 font-14">
                      Enter your email address below
                    </p>
                    <div className="login-one-inputs mt-4">
                      <input
                        type="text"
                        placeholder="Email Address"
                        onChange={(event) => {
                          setEmail(event.target.value);
                          setEmailError("");
                        }}
                      />
                      <span>
                        <i className="las la-envelope"></i>{" "}
                      </span>
                    </div>
                    {emailError && (
                      <div className="text-danger">{emailError}</div>
                    )}
                    <div className="login-one-inputs mt-3 text-center">
                      <button
                        className="btn-login"
                        type="button"
                        onClick={submitHandler}
                      >
                        SUBMIT
                      </button>
                    </div>
                    <div
                      className="text-center"
                      style={{ padding: "20px 0", fontWeight: "700" }}
                    >
                      <Link className="">Back To Home</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Forgotpwd;
