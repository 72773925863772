import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { deleteProjectUrl, totalProjectsUrl } from "../../globalURL";
import Userdashsidebar from "../Userdashsidebar";
import { toast } from "react-toastify";
import Spinner from "../project/Spinner";
import { updateProjectStatusPendingUrl } from "../../globalURL";
import callPostApi from "../../services/api";

const Totalprojects = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dataFetchedRef = useRef(false);
  const [allProjectsList, setAllProjectsList] = useState([]);
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);

  let current = 1;
  let pageLimit = 12;
  const [currentPage, setCurrentPage] = useState(current);

  useEffect(() => {
    // console.log("ddd", queryParams.get("page"));
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    setIsLoading(true);
    setPageNumber();
  }, []);

  const setPageNumber = () => {
    if (queryParams.get("page")) {
      setCurrentPage(queryParams.get("page"));
      fetchAllProjects(parseInt(queryParams.get("page")), pageLimit);
    } else {
      fetchAllProjects(current, pageLimit);
    }
  };

  const fetchAllProjects = (page, page_limit) => {
    if (page === 0 || page === null || page === "") {
      page = 1;
      setCurrentPage(1);
    }

    callPostApi(totalProjectsUrl, {
      page: page,
      page_limit: page_limit,
    })
      .then((response) => {
        setIsLoading(false);
        if (response?.data?.status === "valid") {
          setAllProjectsList(response.data.data);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error("Something error occured..!, Please try again.");
        // alert("failed")
        console.log(error);
      });
  };

  const deleteProjectNow = (projectId) => {
    callPostApi(deleteProjectUrl, { project_id: projectId })
      .then((response) => {
        setIsLoading(false);
        if (response?.data?.status === "valid") {
          fetchAllProjects(parseInt(currentPage), pageLimit);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error("Something error occured..!, Please try again.");
        console.log(error);
      });
  };

  // project navigate <>

  const editProjectProgram = (projectPhase, projectID) => {
    // projectID
    localStorage.setItem("project_id", projectID);
    localStorage.setItem("phase", projectPhase);
    const path =
      projectPhase === "As Operated" || projectPhase === "Operations Phase"
        ? "/operational"
        : "/projectinformation";
    navigate(path, { state: { project_id: projectID } });
  };

  const updateProjectStatusPending = (projectPhase, projectID) => {
    localStorage.setItem("phase", projectPhase);
    localStorage.setItem("project_id", projectID);

    const path =
      projectPhase === "As Operated" || projectPhase === "Operations Phase"
        ? "/operational"
        : "/chiller";

    const project_id = localStorage.getItem("project_id");
    if (project_id) {
      callPostApi(updateProjectStatusPendingUrl, {
        project_status: "pending",
        project_id,
      })
        .then((response) => {
          setIsLoading(false);
          if (response?.data?.status === "valid") {
            toast.success(response["data"]["message"]);
            navigate(path, { state: { project_id: projectID } });
          } else {
            navigate("/projectinformation");
          }
        })
        .catch((error) => {
          setIsLoading(false);
          toast.error("Something error occured..!, Please try again.");
          console.log(error);
        });
    }
  };

  // project navigate </>

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <Userdashsidebar currentPage={"totalprojects"} />
          <div className="content-page mt-0 class-back-ground border-colo-top">
            <div className="content">
              <div className="container-fluid">
                <div className="row justify-content-center">
                  <div className="col-lg-12 pl-lg-4 pr-lg-3">
                    <div className="card transparent">
                      <div className="card-body pt-1">
                        <div className="row justify-content-between">
                          <div className="col-lg-12">
                            <div
                              className="page-title-box"
                              style={{ padding: "40px 0px 10px 0" }}
                            >
                              <h4
                                className="table-title page-title-underline"
                                style={{ color: "#3b3f5c" }}
                              >
                                Project List
                              </h4>
                            </div>
                          </div>
                        </div>

                        <div className="row profilepg">
                          <div className="col-lg-12">
                            <div className="">
                              {/* <h4 className="table-title page-sub-title-underline">
                                  Recent Projects
                                </h4> */}
                              <div id="resptable ">
                                <table className="table custom_table_design">
                                  <thead>
                                    <tr>
                                      <th className="text-center">S.No.</th>
                                      <th style={{ textAlign: "left" }}>
                                        GSAS Project ID
                                      </th>
                                      <th style={{ textAlign: "left" }}>
                                        Project Name
                                      </th>
                                      <th className="text-center">
                                        Plant Size (TR)
                                      </th>
                                      <th className="text-center">Phase </th>
                                      <th className="text-center">Date</th>
                                      <th className="text-center">Status </th>
                                      <th className="text-center">Action</th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    {allProjectsList.projects &&
                                      allProjectsList.projects.map(
                                        (app, index) => (
                                          <tr key={index}>
                                            {/* <td>{formData.title}</td> */}
                                            <td>
                                              {index +
                                                (currentPage - 1) * 12 +
                                                1}
                                            </td>
                                            {/* <td>{app.project_date}</td> */}

                                            <td style={{ textAlign: "left" }}>
                                              {app.gsas_project_id}
                                            </td>
                                            <td
                                              style={{
                                                textAlign: "left",
                                                width: "25%",
                                                minWidth: "130px",
                                              }}
                                            >
                                              {app.project_name || "-"}
                                            </td>
                                            <td>{app.plant_size || "-"}</td>
                                            <td>{app.project_phase || "-"}</td>
                                            <td>
                                              {new Date(
                                                app.project_date
                                              ).toLocaleDateString("en-CA")}
                                            </td>
                                            <td className="text-uppercase text-center">
                                              <span
                                                className={
                                                  app.project_status ===
                                                  "completed"
                                                    ? "status-primary"
                                                    : "status-warning"
                                                }
                                              >
                                                {app.project_status}
                                              </span>
                                            </td>

                                            <td
                                              className="text-left"
                                              style={{ width: "190px" }}
                                            >
                                              {app.project_status ===
                                                "completed" && (
                                                <button
                                                  onClick={() =>
                                                    updateProjectStatusPending(
                                                      app.project_phase,
                                                      app._id.$oid
                                                    )
                                                  }
                                                  title="Edit"
                                                  className="btn btn-sm btn-outline-info"
                                                  style={{
                                                    padding: "5px 16px",
                                                  }}
                                                >
                                                  Edit
                                                </button>
                                              )}
                                              {app.project_status ===
                                                "pending" && (
                                                <button
                                                  onClick={() =>
                                                    editProjectProgram(
                                                      app.project_phase,
                                                      app._id.$oid
                                                    )
                                                  }
                                                  className="btn btn-sm btn-outline-info"
                                                  style={{
                                                    padding: "5px 16px",
                                                  }}
                                                >
                                                  Edit
                                                </button>
                                              )}{" "}
                                              <Link
                                                to={
                                                  "/details?s=" +
                                                  app.project_status +
                                                  "&project_id=" +
                                                  app._id.$oid
                                                }
                                                target="_blank"
                                                className="btn btn-sm btn-outline-warning"
                                                style={{ padding: "4px 8px" }}
                                              >
                                                {/* <i className="fe-eye fa-md"></i> &nbsp; */}
                                                Export
                                              </Link>{" "}
                                              &nbsp;
                                              {app.project_status ===
                                              "completed" ? (
                                                <Link
                                                  to={
                                                    "/final-result?s=" +
                                                    app.project_status +
                                                    "&project_id=" +
                                                    app._id.$oid
                                                  }
                                                  className="btn btn-sm btn-outline-success"
                                                  style={{ padding: "4px 8px" }}
                                                >
                                                  {/* <i className="fe-eye fa-md"></i> &nbsp;  */}
                                                  Result
                                                </Link>
                                              ) : (
                                                <>
                                                  <button
                                                    className="btn btn-outline-danger btn-sm"
                                                    style={{
                                                      padding: "4px 8px",
                                                    }}
                                                    onClick={() => {
                                                      if (
                                                        window.confirm(
                                                          'Are you sure Delete this "' +
                                                            app.project_name +
                                                            '" Project?'
                                                        )
                                                      ) {
                                                        deleteProjectNow(
                                                          app._id.$oid
                                                        );
                                                      }
                                                    }}
                                                  >
                                                    {/* <i className="fe-trash-2 fa-md"></i> &nbsp; */}
                                                    Delete
                                                  </button>
                                                </>
                                              )}
                                            </td>
                                          </tr>
                                        )
                                      )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            {/* <pre style={{ color: "#fff" }}>
                                                            {JSON.stringify(allProjectsList, null, 2)}
                                                        </pre> */}

                            {allProjectsList.total_pages > 1 && (
                              <div className="row justify-content-center">
                                <div className="align-items-center justify-content-center">
                                  <center>
                                    <nav aria-label="...">
                                      <ul className="pagination">
                                        <li
                                          className={
                                            "page-item " +
                                            (parseInt(currentPage) === null)
                                              ? "disabled"
                                              : ""
                                          }
                                        >
                                          <a
                                            className="page-link"
                                            href={
                                              "/totalprojects?page=" +
                                              (parseInt(currentPage) - 1)
                                            }
                                            tabIndex="-1"
                                          >
                                            Previous
                                          </a>
                                        </li>
                                        {allProjectsList.total_pages &&
                                          Array.apply(
                                            0,
                                            Array(allProjectsList.total_pages)
                                          ).map((x, i) => {
                                            return (
                                              <li
                                                className={
                                                  i + 1 ===
                                                  parseInt(currentPage)
                                                    ? "page-item active"
                                                    : "page-item"
                                                }
                                              >
                                                <a
                                                  className="page-link"
                                                  href={
                                                    "/totalprojects?page=" +
                                                    (i + 1)
                                                  }
                                                >
                                                  {i + 1}
                                                  {/* <span className="sr-only">(current)</span> */}
                                                </a>
                                              </li>
                                            );
                                          })}

                                        {/* <li className="page-item"><a className="page-link" href="#">1</a></li>
                                                                            <li className="page-item active">
                                                                                <a className="page-link" href="#">2 <span className="sr-only">(current)</span></a>
                                                                            </li>
                                                                            <li className="page-item"><a className="page-link" href="#">3</a></li> */}
                                        <li
                                          className={
                                            allProjectsList.total_pages ===
                                            parseInt(currentPage)
                                              ? "page-item disabled"
                                              : "page-item"
                                          }
                                        >
                                          <a
                                            className="page-link"
                                            href={
                                              "/totalprojects?page=" +
                                              (parseInt(currentPage) + 1)
                                            }
                                          >
                                            Next
                                          </a>
                                        </li>
                                      </ul>
                                    </nav>
                                  </center>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Totalprojects;
