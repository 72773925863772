import React, { useEffect, useRef, useState } from "react";
import Userdashsidebar from "../Userdashsidebar";
import "../../css/dashboardnewstyles.css";
import { Link, useNavigate } from "react-router-dom";
import {
  totalProjectsUrl,
  updateProjectStatusPendingUrl,
  userDashboardStatusUrl,
  userProfileURL,
} from "../../globalURL";
import callPostApi from "../../services/api";
import { toast } from "react-toastify";
import Spinner from "../project/Spinner";

const Dashboardinfo = () => {
  const dataFetchedRef = useRef(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [allProjectsList, setAllProjectsList] = useState([]);

  const [allstats, setAllstats] = useState({
    all_projects: 0,
    completed_projects: 0,
    pending_projects: 0,
    support_requests: 0,
    user_has_package: 0,
  });
  const [userDetails, setuserDetails] = useState({});

  const timestamp = userDetails?.last_login_time;
  const date = new Date(timestamp);
  const formattedDate = date.toLocaleString();

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    setIsLoading(true);
    getUserProfileDetails();
    fetchAllProjects();
    dashStats();
  }, []);

  const getUserProfileDetails = () => {
    callPostApi(userProfileURL, {})
      .then((response) => {
        setIsLoading(false);
        if (response?.data?.data) {
          setuserDetails(response?.data?.data);
        } else {
          toast.error("User not authorized");
          localStorage.clear();
          navigate("/login");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error("Something error occured..!, Please try again.");
        console.error(error);
      });
  };

  const fetchAllProjects = (page, page_limit) => {
    callPostApi(totalProjectsUrl, { page: 1, page_limit: 10 })
      .then((response) => {
        setIsLoading(false);
        if (response?.data?.status === "valid") {
          setAllProjectsList(response.data.data.projects);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error("Something error occured..!, Please try again.");
        console.log(error);
      });
  };

  const dashStats = () => {
    callPostApi(userDashboardStatusUrl, {})
      .then((response) => {
        setIsLoading(false);
        if (response?.data?.status === "valid") {
          setAllstats(response.data.data);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error("Something error occured..!, Please try again.");
        console.log(error);
      });
  };
  const editProjectProgram = (projectPhase, projectID) => {
    localStorage.setItem("project_id", projectID);
    localStorage.setItem("phase", projectPhase);
    const path =
      projectPhase === "As Operated" || projectPhase === "Operations Phase"
        ? "/operational"
        : "/projectinformation";
    navigate(path, { state: { project_id: projectID } });
  };

  const updateProjectStatusPending = (projectPhase, projectID) => {
    localStorage.setItem("phase", projectPhase);
    localStorage.setItem("project_id", projectID);

    const path =
      projectPhase === "As Operated" || projectPhase === "Operations Phase"
        ? "/operational"
        : "/chiller";

    const { access_token } = userDetails;
    const project_id = localStorage.getItem("project_id");
    if (project_id) {
      callPostApi(updateProjectStatusPendingUrl, {
        project_status: "pending",
        project_id,
        access_token,
      })
        .then((response) => {
          setIsLoading(false);
          if (response?.data?.status === "valid") {
            toast.success(response["data"]["message"]);
            // navigate("/chiller");
            navigate(path, { state: { project_id: projectID } });
          } else {
            navigate("/projectinformation");
          }
        })
        .catch((error) => {
          setIsLoading(false);
          toast.error("Something error occured..!, Please try again.");
          console.log(error);
        });
    }
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <Userdashsidebar currentPage={"dashboard"} />
          <div className="content-page mt-0 class-back-ground border-colo-top">
            <div className="content">
              <div className="container-fluid">
                <div className="row justify-content-center">
                  <div className="col-lg-12 pl-lg-4 pr-lg-3">
                    <div className="card transparent">
                      <div className="card-body pt-1">
                        <div className="row justify-content-between">
                          <div
                            className={
                              allstats.user_has_package === 0
                                ? "col-lg-6"
                                : "col-lg-9"
                            }
                          >
                            <div className="page-title-box">
                              <h4
                                className="page-title pb-0 "
                                style={{ color: "#3b3f5c" }}
                              >
                                Welcome To Dashboard {process.env.APP_MODE}
                              </h4>
                            </div>
                          </div>

                          {allstats.user_has_package > 0 && (
                            <>
                              <div className="col-lg-2 text-right align-self-center">
                                <Link
                                  to="/projectinformation"
                                  path="relative"
                                  className="btn btn-sm btn-success mt-2"
                                  onClick={() => {
                                    localStorage.setItem("project_id", "");
                                    localStorage.setItem(
                                      "package_transaction_id",
                                      ""
                                    );
                                  }}
                                >
                                  <i className="fe-plus-circle"></i> Create
                                  Project
                                </Link>
                              </div>
                            </>
                          )}
                          {allstats.user_has_package === 0 && (
                            <>
                              <div
                                className="col-lg-6 text-right align-self-center"
                                style={{ color: "#00000094" }}
                              >
                                <b>
                                  Please wait until we activate your
                                  subscription packages{" "}
                                </b>
                              </div>
                            </>
                          )}
                        </div>
                        <div className="row">
                          <div className="col-lg-12">
                            <p className="p-color">
                              Last Login Date & Time :{formattedDate}
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-3 col-md-3 col-sm-6">
                            <div className="card widget-box-two bg-gradient-info border-0">
                              <div className="card-body">
                                <div className="media">
                                  <div className="media-body wigdet-two-content">
                                    <p
                                      className="m-0 text-uppercase text-white"
                                      title="Statistics"
                                    >
                                      Total Projects
                                    </p>
                                    <h2 className="text-white">
                                      <span data-plugin="counterup">
                                        {allstats.all_projects}
                                      </span>
                                      <i className="mdi mdi-arrow-up text-white font-22"></i>
                                    </h2>
                                  </div>
                                  <div className="avatar-lg rounded-circle bg-soft-light ml-2 align-self-center">
                                    <i className="fas fa-building font-22 avatar-title text-white"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-3 col-md-3 col-sm-6">
                            <div className="card widget-box-two bg-gradient-success border-0">
                              <div className="card-body">
                                <div className="media">
                                  <div className="media-body wigdet-two-content">
                                    <p
                                      className="m-0 text-uppercase text-white"
                                      title="Statistics"
                                    >
                                      Completed Projects
                                    </p>
                                    <h2 className="text-white">
                                      <span data-plugin="counterup">
                                        {allstats.all_projects -
                                          allstats.pending_projects}
                                      </span>
                                      <i className="mdi mdi-arrow-up text-white font-22"></i>
                                    </h2>
                                  </div>
                                  <div className="avatar-lg rounded-circle bg-soft-light ml-2 align-self-center">
                                    <i className="fas fa-building font-22 avatar-title text-white"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3  col-md-3 col-sm-6">
                            <div className="card widget-box-two bg-gradient-warning border-0">
                              <div className="card-body">
                                <div className="media">
                                  <div className="media-body wigdet-two-content">
                                    <p
                                      className="m-0 text-uppercase text-white"
                                      title="Statistics"
                                    >
                                      Pending Projects
                                    </p>
                                    <h2 className="text-white">
                                      <span data-plugin="counterup">
                                        {allstats.pending_projects}
                                      </span>
                                      <i className="mdi mdi-arrow-up text-white font-22"></i>
                                    </h2>
                                  </div>
                                  <div className="avatar-lg rounded-circle bg-soft-light ml-2 align-self-center">
                                    <i className="fas fa-building font-22 avatar-title text-white"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-3 col-sm-6">
                            <div className="card widget-box-two bg-gradient-dark border-0">
                              <div className="card-body">
                                <div className="media">
                                  <div className="media-body wigdet-two-content">
                                    <p
                                      className="m-0 text-uppercase text-white"
                                      title="Statistics"
                                    >
                                      Help &amp; Support Requests
                                    </p>
                                    <h2 className="text-white">
                                      <span data-plugin="counterup">
                                        {allstats.support_requests}
                                      </span>
                                      <i className="mdi mdi-arrow-up text-white font-22"></i>
                                    </h2>
                                  </div>
                                  <div className="avatar-lg rounded-circle bg-soft-light ml-2 align-self-center">
                                    <i className="fas fa-comment font-22 avatar-title text-white"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-lg-12">
                            <h4 className="table-title page-title-underline">
                              Recent Projects
                            </h4>
                          </div>
                          <div className="col-lg-12">
                            <table className="table custom_table_design">
                              <thead>
                                <tr>
                                  <th>S.No.</th>
                                  <th style={{ textAlign: "left" }}>
                                    {" "}
                                    Project ID
                                  </th>
                                  <th style={{ textAlign: "left" }}>
                                    Project Name
                                  </th>
                                  <th>Plant Size (TR)</th>
                                  <th>Phase </th>
                                  <th>Date</th>
                                  <th>Status </th>
                                  <th
                                    style={{
                                      textAlignLast: "left",
                                      maxWidth: "150px",
                                      paddingLeft: "20px",
                                    }}
                                  >
                                    Action
                                  </th>
                                </tr>
                              </thead>

                              <tbody>
                                {allProjectsList &&
                                  allProjectsList.map((app) => (
                                    <tr key={allProjectsList.indexOf(app)}>
                                      <td>
                                        {allProjectsList.indexOf(app) + 1}
                                      </td>
                                      <td style={{ textAlign: "left" }}>
                                        {app.gsas_project_id}
                                      </td>
                                      <td style={{ textAlign: "left" }}>
                                        {app.project_name || "-"}
                                      </td>
                                      <td>{app.plant_size || "-"}</td>
                                      <td>{app.project_phase || "-"}</td>
                                      <td>
                                        {new Date(
                                          app.project_date
                                        ).toLocaleDateString("en-CA")}
                                      </td>
                                      <td className="text-uppercase text-center">
                                        <span
                                          className={
                                            app.project_status === "completed"
                                              ? "status-primary"
                                              : "status-warning"
                                          }
                                        >
                                          {app.project_status}
                                        </span>
                                      </td>
                                      <td
                                        className="text-center"
                                        style={{
                                          textAlignLast: "left",
                                          maxWidth: "150px",
                                          width: "140px",
                                        }}
                                      >
                                        {app.project_status === "completed" && (
                                          <button
                                            onClick={() =>
                                              updateProjectStatusPending(
                                                app.project_phase,
                                                app._id.$oid
                                              )
                                            }
                                            title="Edit"
                                            className="btn btn-sm btn-outline-info"
                                            style={{ padding: "5px 16px" }}
                                          >
                                            Edit
                                          </button>
                                        )}
                                        {app.project_status === "pending" && (
                                          <button
                                            onClick={() =>
                                              editProjectProgram(
                                                app.project_phase,
                                                app._id.$oid
                                              )
                                            }
                                            className="btn btn-sm btn-outline-info"
                                            style={{ padding: "5px 16px" }}
                                          >
                                            Edit
                                          </button>
                                        )}{" "}
                                        {app.project_status === "completed" && (
                                          <Link
                                            to={
                                              "/final-result?s=" +
                                              app.project_status +
                                              "&project_id=" +
                                              app._id.$oid
                                            }
                                            title="View Result"
                                            className="btn btn-sm btn-outline-success"
                                            style={{ padding: "5px 11px" }}
                                          >
                                            Result
                                          </Link>
                                        )}
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Dashboardinfo;
