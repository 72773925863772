const commonTest = () => {
  alert("This is test function written in common helper");
};

export const commonTests = () => {
  alert("This is test ");
};

export const getIpAddress = () => {
  const pc = new RTCPeerConnection();
  pc.createDataChannel("");

  pc.createOffer()
    .then((offer) => pc.setLocalDescription(offer))
    .catch((error) => console.error(error));

  pc.onicecandidate = (event) => {
    if (event.candidate) {
      const ipRegex = /(?:\d{1,3}\.){3}\d{1,3}/;
      const candidate = event.candidate.candidate;
      const ipAddressMatch = ipRegex.exec(candidate);

      if (ipAddressMatch && ipAddressMatch.length > 0) {
        const ipAddress = ipAddressMatch[0];
        console.log("Visitor IP address:", ipAddress);
        return ipAddress;
      } else {
        console.error("Unable to extract IP address");
        console.log("Candidate:", candidate);
        return "";
      }
    }
  };
};

export const downloadAnyFile = (filename, url) => {
  // alert("This is test downloadAnyFile")
  // const filename = "dowloadfile.csv";
  const aTag = document.createElement("a");
  aTag.href = url;
  aTag.setAttribute("download", filename);
  document.body.append(aTag);
  aTag.click();
  aTag.remove();
};
export const getProjectLocalId = () => {
  return localStorage.getItem("project_id");
};
export const getAccessTokenLocal = () => {
  return localStorage.getItem("access_token");
};
export const getCurrentPackageSelected = () => {
  return localStorage.getItem("package_transaction_id");
};
export default commonTest;
