import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { cmsPagesURL } from "../../globalURL";
import Userdashsidebar from "../Userdashsidebar";
import { toast } from "react-toastify";
import Spinner from "../project/Spinner";
import { callGetApi } from "../../services/api";

const CmsPages = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dataFetchedRef = useRef(false);
  const [cmsData, setCmsData] = useState([]);
  const [currentIndex, setcurrentIndex] = useState(0);
  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    setIsLoading(true);
    fetchRequests();
  }, []);

  const fetchRequests = async (event) => {
    try {
      const response = await callGetApi(cmsPagesURL, {});

      setIsLoading(false);
      if (response?.data?.status === "valid") {
        setCmsData(response.data.data);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("An error occurred while submitting the form");
      console.log(error);
    }
  };
  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <div>
            <Userdashsidebar currentPage={"cms-pages"} />
            <div className="content-page mt-0 class-back-ground border-colo-top">
              <div className="content">
                <div className="container-fluid">
                  <div className="row justify-content-center">
                    <div className="col-lg-12 pl-lg-4 pr-lg-3">
                      <div className="card transparent">
                        <div className="card-body pt-1">
                          <div className="row pt-lg-0 pt-3">
                            <div className="col-lg-12">
                              {/* <pre style={{ color: "#fff" }}>
                                {JSON.stringify(cmsData, null, 2)}
                              </pre> */}
                              <ul className="cmsmenu">
                                {cmsData &&
                                  cmsData.map((app, index) => (
                                    <li
                                      className={
                                        index === currentIndex ? "active" : " "
                                      }
                                      onClick={() => setcurrentIndex(index)}
                                      key={index}
                                    >
                                      <Link>{app.title}</Link>
                                    </li>
                                  ))}
                              </ul>
                            </div>
                          </div>
                          {cmsData &&
                            cmsData.map((app, index) => {
                              if (index === currentIndex) {
                                return (
                                  <>
                                    <div className="row justify-content-center">
                                      <div className="col-lg-12">
                                        <div className="page-title-box">
                                          <h4
                                            className="page-title"
                                            style={{ color: "#3b3f5c" }}
                                          >
                                            {app.title}
                                          </h4>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row cmsnewblock">
                                      <div className="col-lg-12">
                                        <p className="page-content text-black">
                                          {app.description}
                                        </p>
                                      </div>
                                    </div>
                                  </>
                                );
                              }
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default CmsPages;
