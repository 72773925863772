import React, { useEffect, useRef } from "react";
import Userdashsidebar from "../Userdashsidebar";
import { Link, useNavigate } from "react-router-dom";

export const PaymentSuccessPage = () => {
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const tid = queryParams.get("transaction_id");
  const tstatus = queryParams.get("status");
  const dataFetchedRef = useRef(false);
  useEffect(() => {
    // alert()
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    if (tid === null || tid === "") {
      navigate("/dashboard");
    }
  }, [tid]);

  // console.log('tstatus', tstatus);
  const acceptContent = () => {
    return (
      <>
        <i className="fe-check-circle fa-8x" style={{ color: "#07e307" }}></i>

        <h3
          className=" text-center"
          style={{
            fontSize: "20px",
            padding: "20px",
            color: "#fff",
          }}
        >
          Payment Success
        </h3>
        <p
          style={{
            fontSize: "16px",
            padding: "20px",
            color: "#fff",
          }}
        >
          Thankyou..! <br />
          Your payment successfully Completed.
          <br />
          <br />
          Transaction Id : <b>{tid}</b>
        </p>
        <Link to="/dashboard" className="btn btn-md btn-success">
          Go Home
        </Link>
      </>
    );
  };

  const processContent = () => {
    return (
      <>
        <i className="fe-clock fa-8x" style={{ color: "#ff9800" }}></i>
        <h3
          className=" text-center"
          style={{
            fontSize: "20px",
            padding: "20px",
            color: "#fff",
          }}
        >
          Payment Processing
        </h3>
        <p
          style={{
            fontSize: "16px",
            padding: "20px",
            color: "#fff",
          }}
        >
          Thankyou..! <br />
          Your payment Under Process.Please wait for admin approval.
          <br />
          <br />
          Transaction Id : <b>{tid}</b>
        </p>
        <Link to="/payments_transactions" className="btn btn-md btn-info">
          <i className="fe-dollar-sign" style={{ color: "#fff" }}></i> Go to
          Payments
        </Link>
        &nbsp;&nbsp;&nbsp;
        <Link to="/dashboard" className="btn btn-md btn-success">
          <i className="fe-grid " style={{ color: "#fff" }}></i> Go to Dashboard
        </Link>
      </>
    );
  };

  const failedContent = () => {
    return (
      <>
        <i
          className="fe-x-circle fa-8x"
          style={{ color: "rgb(186 10 10)" }}
        ></i>

        <h3
          className=" text-center"
          style={{
            fontSize: "20px",
            padding: "20px",
            color: "#fff",
          }}
        >
          Payment Failed
        </h3>
        <p
          style={{
            fontSize: "16px",
            padding: "20px",
            color: "#fff",
          }}
        >
          Your payment transaction was failed. <br />
          Try again.
          <br />
          <br />
          {/* Transaction Id : <b>{tid}</b> */}
        </p>
        <Link to="/packages" className="btn btn-md btn-success">
          Go To Packages {tstatus}
        </Link>
      </>
    );
  };

  return (
    <>
      <div>
        <Userdashsidebar />
        <div className="content-page mt-0 class-back-ground border-colo-top">
          <div className="content">
            <div className="container-fluid">
              <div className="row justify-content-center">
                <div className="col-lg-10">
                  <div className="card transparent">
                    <div className="card-body pt-1">
                      <div className="row justify-content-center">
                        <div className="col-lg-12">
                          <div className="page-title-box">
                            {/* <h4 className="page-title text-center">Payment Success</h4> */}
                          </div>
                        </div>
                        <div className="col-lg-12 text-center mt-40">
                          {/* check-circle  */}
                          <br />
                          <br />
                          {/* <i className="fe-check-circle fa-8x" style={{color:"#07e307"}}></i>
                                                        <h3 className=" text-center" style={{fontSize:"20px",padding:"20px",color:"#fff"}}>Payment Success</h3>
                                                        <p  style={{fontSize:"16px",padding:"20px",color:"#fff"}}>
                                                            Thankyou..! <br/>
                                                        Your payment successfully Completed.
                                                        <br/>
                                                        <br/>
                                                        Transaction Id : <b>{tid}</b>
                                                        </p>
                                                        <Link to="/dashboard" className='btn btn-md btn-success'>Go Home</Link> */}

                          {tstatus === "Processing"
                            ? processContent()
                            : tstatus === "ACCEPT"
                              ? acceptContent()
                              : failedContent()}
                        </div>
                        <div className="col-lg-12 text-center"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
