import React from "react";

export default function ThermalProfile(props) {
  const { setShowTable, handleChargeChange, handleDischargeChange, formData } =
    props;
  return (
    <div className='storethr'>
      <div className="modal show thrprofile" role="dialog" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.7)' }}>
        <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: '1000px' }} role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title p-color">Thermal Store Profile - User Input</h5>
              <button type="button" className="btn-close" aria-label="Close" onClick={() => setShowTable(false)}>
                <i className="lnr lnr-cross-circle"></i>
              </button>
            </div>
            <div className="modal-body">
              <h5 className="mb-2 text-white ">Daily Charging Cycle(24H)</h5>
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6 table-bacg-color">
                  {/* <pre style={{ color: "#fff" }}>
                      {JSON.stringify(formData, null, 2)}
                    </pre> */}
                  <table className="table table-colorchild">
                    <thead>
                      <tr className='bg-success'>
                        <th>Time</th>
                        <th>Charge</th>
                        <th>Discharge</th>
                      </tr>
                    </thead>
                    <tbody>
                      {formData.thermal_store_profile && formData.thermal_store_profile.map((app, index) =>
                        (index < 12) ? <tr key={index}>
                          <td>
                            {/* <pre style={{ color: "#fff" }}>
                                {JSON.stringify(formData.thermal_store_profile[index], null, 2)}
                              </pre> */}

                            {app.daily_charging_cycle_time}  </td>
                          <td>

                            <select
                              className="form-select"
                              onChange={(event) => handleChargeChange(event, app.id)}
                              value={formData.thermal_store_profile[index].daily_charging_cycle_charge}
                            >
                              <option value="No" selected={formData.thermal_store_profile[index].daily_charging_cycle_charge === "No"}>No</option>
                              <option value="Yes" selected={formData.thermal_store_profile[index].daily_charging_cycle_charge === "Yes"}>Yes</option>

                            </select>
                          </td>
                          <td>
                            <select
                              className="form-select"
                              onChange={(event) => handleDischargeChange(event, app.id)}
                            >
                              <option value="No" selected={formData.thermal_store_profile[index].daily_charging_cycle_discharge === "No"}>No</option>
                              <option value="Yes" selected={formData.thermal_store_profile[index].daily_charging_cycle_discharge === "Yes"}>Yes</option>
                            </select>
                          </td>

                        </tr> : ""
                      )}

                    </tbody>
                  </table>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 table-bacg-color">
                  <table className="table table-colorchild">
                    <thead>
                      <tr className='bg-success'>
                        <th>Time</th>
                        <th>Charge</th>
                        <th>Discharge</th>
                      </tr>
                    </thead>
                    <tbody>
                      {formData.thermal_store_profile && formData.thermal_store_profile.map((app, index) =>
                        (index >= 12) ? <tr key={index}>
                          <td>{app.daily_charging_cycle_time}
                            {/* <pre style={{ color: "#fff" }}>
                                {JSON.stringify(formData.thermal_store_profile[index], null, 2)}
                              </pre> */}

                          </td>
                          <td>
                            {formData.thermal_store_profile[index].daily_charging_cycle_charge}
                            <select
                              className="form-select"
                              onChange={(event) => handleChargeChange(event, app.id)}
                              value={formData.thermal_store_profile[index].daily_charging_cycle_charge}
                            >
                              <option value="No" selected={formData.thermal_store_profile[index].daily_charging_cycle_charge === "No"}>No</option>
                              <option value="Yes" selected={formData.thermal_store_profile[index].daily_charging_cycle_charge === "Yes"}>Yes</option>

                            </select>
                          </td>
                          <td>
                            <select
                              className="form-select"
                              onChange={(event) => handleDischargeChange(event, app.id)}
                            >
                              <option value="No" selected={formData.thermal_store_profile[index].daily_charging_cycle_discharge === "No"}>No</option>
                              <option value="Yes" selected={formData.thermal_store_profile[index].daily_charging_cycle_discharge === "Yes"}>Yes</option>
                            </select>
                          </td>

                        </tr> : ""
                      )}

                    </tbody>
                  </table>
                </div>
              </div>
              <div className="row justify-content-end">
                <div className="col-lg-2 prj">
                  <button onClick={() => setShowTable(false)} type="submit" className='btn btn-pryellow px-4 py-1'>Ok</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}
