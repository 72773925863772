import React, { useEffect, useRef, useState } from "react";
import { supportRequestsUrl, addSupportRequestUrl } from "../../globalURL";
import Userdashsidebar from "../Userdashsidebar";
import { toast } from "react-toastify";
import Spinner from "../project/Spinner";
import callPostApi from "../../services/api";
const Helpsupport = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dataFetchedRef = useRef(false);
  const [formData, setFormData] = useState({
    title: "",
    message: "",
  });
  const [formDataErrors, setFormDataErrors] = useState({
    title: "",
    message: "",
  });
  const [allRequests, setAllRequests] = useState([]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    setIsLoading(true);
    fetchRequests();
  }, []);

  const fetchRequests = async (event) => {
    try {
      const response = await callPostApi(supportRequestsUrl, {
        page: 1,
        page_limit: 10,
      });
      setIsLoading(false);
      if (response?.data?.status === "valid") {
        setAllRequests(response.data.data);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("An error occurred while submitting the form");
      console.log(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const errors = {};
    if (!formData.title) {
      errors.title = "Please enter title";
    }
    if (!formData.message) {
      errors.message = "Please enter new message";
    }

    if (Object.keys(errors).length > 0) {
      setFormDataErrors(errors);
      setIsLoading(false);
      return;
    }
    // service call here
    callPostApi(addSupportRequestUrl, {
      title: formData.title,
      message: formData.message,
    })
      .then((res) => {
        setIsLoading(false);
        if (res.data["status"] === "invalid") {
          toast.error(res.data.message);
        } else {
          fetchRequests();
          setFormData({
            title: "",
            message: "",
          });
          toast.success(res.data.message);
        }
        if (res?.status === "valid") {
          toast.success(res?.data?.message);
        }
      })
      .catch((error) => {
        toast.error("Passwords not matching");
        console.log(error);
      });
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <div>
            <Userdashsidebar currentPage={"support"} />
            <div className="content-page mt-0 class-back-ground border-colo-top">
              <div className="content">
                <div className="container-fluid">
                  <div className="row justify-content-center">
                    <div className="col-lg-12 pl-lg-4 pr-lg-3">
                      <div className="card transparent">
                        <div className="card-body pt-1">
                          <div className="row justify-content-center">
                            <div className="col-lg-12">
                              <div className="page-title-box">
                                <h4
                                  className="page-title"
                                  style={{ color: "#3b3f5c" }}
                                >
                                  Help & Support
                                </h4>
                              </div>
                            </div>
                          </div>
                          <form action="" method="post" onSubmit={handleSubmit}>
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="row">
                                  <div className="col-lg-12">
                                    <div className="form-group">
                                      <label className="input_label" htmlFor="">
                                        Title
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="floatingInput"
                                        placeholder="Ex.1144"
                                        value={formData.title}
                                        name="title"
                                        onChange={handleChange}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-12">
                                    <div className="form-group">
                                      <label className="input_label" htmlFor="">
                                        Message
                                      </label>
                                      <textarea
                                        value={formData.message}
                                        name="message"
                                        onChange={handleChange}
                                        className="form-control"
                                        id="floatingInput1"
                                        placeholder=""
                                        rows="6"
                                      ></textarea>
                                    </div>
                                  </div>
                                  <div className="form-group text-right">
                                    <button
                                      type="submit"
                                      className="btn btn-primary mr-1 waves-effect waves-light ml-2"
                                    >
                                      Submit
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="row profilepg">
                                <div className="col-lg-12">
                                  <div id="resptable">
                                    <table className="table custom_table_design">
                                      <thead>
                                        <tr>
                                          <th>S.no</th>
                                          <th>Title</th>
                                          <th>message</th>
                                          <th>Remarks</th>
                                          <th>Created At</th>
                                          <th>Status</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {/* //allRequests */}
                                        {allRequests &&
                                          allRequests.map((app) => {
                                            const createdAtDate = new Date(
                                              app.created_at * 1000
                                            );

                                            const day = createdAtDate
                                              .getDate()
                                              .toString()
                                              .padStart(2, "0");
                                            const month = (
                                              createdAtDate.getMonth() + 1
                                            )
                                              .toString()
                                              .padStart(2, "0");
                                            const year = createdAtDate
                                              .getFullYear()
                                              .toString();
                                            // .slice(-2);

                                            const formattedCreatedAt = `${day}-${month}-${year}`;

                                            return (
                                              <>
                                                <tr
                                                  key={allRequests.indexOf(app)}
                                                >
                                                  {/* <td>{formData.title}</td> */}
                                                  <td data-title="S.no">
                                                    {allRequests.indexOf(app) +
                                                      1}
                                                  </td>
                                                  <td data-title="Title">
                                                    {app.title}
                                                  </td>
                                                  <td data-title="Message">
                                                    {app.message}
                                                  </td>
                                                  <td data-title="Remarks">
                                                    {app.remarks || "-"}
                                                  </td>
                                                  <td data-title="Created At">
                                                    {formattedCreatedAt || "-"}
                                                  </td>
                                                  <td data-title="Status">
                                                    <span className="badge badge-warning">
                                                      Pending
                                                    </span>
                                                  </td>
                                                </tr>
                                              </>
                                            );
                                          })}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Helpsupport;
