import React, { useState } from "react";
import { uploadFileOrImage } from "../globalURL";
import callPostApi from "../services/api";

const ImageUpload = () => {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("file", selectedFile);
    const headers = { "Content-Type": "multipart/form-data" };
    callPostApi(uploadFileOrImage,formData, headers)
      .then((response) => response)
      .catch((error) => console.error(error));
  };

  return (
    <form onSubmit={handleSubmit}>
      {selectedFile && (
        <div>
          <img
            alt="not found"
            width={"250px"}
            src={URL.createObjectURL(selectedFile)}
          />
          <br />
          <button onClick={() => setSelectedFile(null)}>Remove</button>
        </div>
      )}

      <input type="file" onChange={handleFileChange} />
      <button type="submit">Upload</button>
    </form>
  );
};

export default ImageUpload;
