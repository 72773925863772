import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Dashboardsidebar from "./Dashboardsidebar";

const ResultasOperated = () => {
  const { state } = useLocation();
  const location = useLocation();
  const projectId = new URLSearchParams(location.search).get("project_id");
  const [formData, setFormData] = useState({
    project_id: localStorage.getItem("project_id"),
    project_date: localStorage.getItem("project_date"),
    project_name: localStorage.getItem("project_name"),
    gsas_energy_efficiency: localStorage.getItem("gsas_energy_efficiency"),
    system_performance: localStorage.getItem("system_performance"),
    trh: localStorage.getItem("trh"),
    system_seer: localStorage.getItem("system_seer"),
  });
  const {
    project_id,
    project_name,
    project_date,
    gsas_energy_efficiency,
    system_performance,
    system_seer,
    trh,
  } = formData || {};

  // console.log("project_id", project_id);
  // console.log("project_name", project_name);
  // console.log("project_date", project_date);
  // console.log("gsas_energy_efficiency", gsas_energy_efficiency);
  // console.log("system_performance", system_performance);
  // console.log("system_seer", system_seer);
  // console.log("trh", trh);

  // console.log("project_id", projectId);

  return (
    <div>
      <Dashboardsidebar />
      <div className="content-page mt-0">
        <div className="content">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-lg-10 col-md-10">
                <div className="card transparent">
                  <div className="card-body pt-1">
                    <div className="row justify-content-center">
                      <div className="col-lg-12">
                        <div className="page-title-box">
                          <h4 className="page-title pb-0">Result</h4>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      {/* <div className="col-lg-12">
                                                <ul className="cmsmenu pb-4">
                                                    <li><a href="/resultasbuilt">AS BUILT</a></li>
                                                    <li className="active"><a href="/resultasoperated">AS OPERATED</a></li>
                                                </ul>
                                            </div> */}
                    </div>
                    <div className="row resultproject">
                      <div className="col-lg-3">
                        <h4>Project ID</h4>
                        <p>{projectId}</p>
                      </div>
                      <div className="col-lg-6">
                        <h4>Project Name</h4>
                        <p>{project_name}</p>
                      </div>
                      <div className="col-lg-3">
                        <h4>Date</h4>
                        <p>{project_date}</p>
                      </div>
                    </div>
                    <hr />
                    <div className="row justify-content-between">
                      <div className="col-lg-7">
                        <div className="row justify-content-between">
                          <div className="col-lg-10">
                            <ul className="efficientbox">
                              <li
                                className={
                                  gsas_energy_efficiency === "A*"
                                    ? "blink_me"
                                    : ""
                                }
                                style={{ width: "30%" }}
                              >
                                A*
                              </li>
                              <li
                                className={
                                  gsas_energy_efficiency === "A"
                                    ? "blink_me"
                                    : ""
                                }
                                style={{ width: "40%" }}
                              >
                                A
                              </li>
                              <li
                                className={
                                  gsas_energy_efficiency === "B"
                                    ? "blink_me"
                                    : ""
                                }
                                style={{ width: "50%" }}
                              >
                                B
                              </li>
                              <li
                                className={
                                  gsas_energy_efficiency === "C"
                                    ? "blink_me"
                                    : ""
                                }
                                style={{ width: "60%" }}
                              >
                                C
                              </li>
                              <li
                                className={
                                  gsas_energy_efficiency === "D"
                                    ? "blink_me"
                                    : ""
                                }
                                style={{ width: "70%" }}
                              >
                                D
                              </li>
                              <li
                                className={
                                  gsas_energy_efficiency === "E"
                                    ? "blink_me"
                                    : ""
                                }
                                style={{ width: "80%" }}
                              >
                                E
                              </li>
                              <li
                                className={
                                  gsas_energy_efficiency === "F"
                                    ? "blink_me"
                                    : ""
                                }
                                style={{ width: "90%" }}
                              >
                                F
                              </li>
                              <li
                                className={
                                  gsas_energy_efficiency === "G"
                                    ? "blink_me"
                                    : ""
                                }
                                style={{ width: "100%" }}
                              >
                                G
                              </li>
                            </ul>
                          </div>
                          <div className="col-lg-1">
                            <div className="gradeA">
                              {gsas_energy_efficiency}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="row">
                          <div className="col-lg-12 col-md-6">
                            <div className="card widget-box-two bg-warning border-0">
                              <div className="card-body">
                                <div className="media">
                                  <div className="media-body wigdet-two-content">
                                    <p className="m-0 text-white fz-20">
                                      System Performance (kW/ton)
                                    </p>
                                    <h2 className="text-white">
                                      <span data-plugin="counterup">
                                        {trh || 0}
                                      </span>{" "}
                                      <i className="mdi mdi-arrow-up font-22"></i>
                                    </h2>
                                  </div>
                                  <div className="avatar-lg rounded-circle bg-soft-light ml-2 align-self-center">
                                    <i className="dripicons-archive font-22 avatar-title text-white"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12 col-md-6">
                            <div className="card widget-box-two border-0 bg-success">
                              <div className="card-body">
                                <div className="media">
                                  <div className="media-body wigdet-two-content">
                                    <p className="m-0 text-white fz-20">
                                      System SEER
                                    </p>
                                    <h2 className="text-white">
                                      <span data-plugin="counterup">
                                        {system_seer || 0}
                                      </span>{" "}
                                      <i className="mdi mdi-arrow-up font-22"></i>
                                    </h2>
                                  </div>
                                  <div className="avatar-lg rounded-circle bg-soft-light ml-2 align-self-center">
                                    <i className="dripicons-archive font-22 avatar-title text-white"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-12 col-md-6">
                            <div className="card widget-box-two bg-danger border-0">
                              <div className="card-body">
                                <div className="media">
                                  <div className="media-body wigdet-two-content">
                                    <p className="m-0 text-white fz-20">
                                      {" "}
                                      Energy Efficiency Rating
                                    </p>
                                    <h2 className="text-white text-center">
                                      {gsas_energy_efficiency || ""}
                                    </h2>
                                  </div>
                                  <div className="avatar-lg rounded-circle bg-soft-light ml-2 align-self-center">
                                    <i className="dripicons-archive font-22 avatar-title text-white"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <div className="col-lg-12 col-md-6">
                            <div className="card widget-box-two bg-primary border-0">
                              <div className="card-body">
                                <div className="media">
                                  <div className="media-body wigdet-two-content">
                                    <p className="m-0 text-white fz-20">
                                      TRH
                                    </p>
                                    <h2 className="text-white text-center">
                                      {trh || 0}
                                      <i className="mdi mdi-arrow-up font-22"></i>
                                    </h2>
                                  </div>
                                  <div className="avatar-lg rounded-circle bg-soft-light ml-2 align-self-center">
                                    <i className="dripicons-archive font-22 avatar-title text-white"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResultasOperated;
