import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Dashboardsidebar from "./Dashboardsidebar";
import Spinner from "./Spinner";
import {
  BaseURL,
  projectDetails,
  pumpsAndOthersAuxUrl,
  pumpsAndOthersUrl,
  uploadFileOrImage,
} from "../../globalURL";
import { toast } from "react-toastify";
import FileUpload from "../dashboard_pages/otherdocumentfile";
import "../../css/background_image.css";
import callPostApi from "../../services/api";

const Auxiliaries = (props) => {
  const navigate = useNavigate();
  const dataFetchedRef = useRef(false);
  const [isLoading, setIsLoading] = useState(false);
  const initialFormData = {
    other_auxiliaries_kwh: "",
    auxiliaries_other_document_file: "",
    _id: localStorage.getItem("project_id"),
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  function handleFileUpload(event) {
    const formDataImage = new FormData();
    formDataImage.append("file", event.target.files[0]);
    const headers = { "Content-Type": "multipart/form-data" };
    callPostApi(uploadFileOrImage, formDataImage, headers).then((response) => {
      // console.log("sss",data["data"]["full_file_address"]);
      setFormData({
        ...formData,
        ["auxiliaries_other_document_file"]:
          response?.data["data"]["full_file_address"],
      });
    });

    // console.log("fileDocName", fileDocName);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (formData.other_auxiliaries_kwh === "") {
      navigate("/cooling-towers");
    } else {
      try {
        const response = await callPostApi(pumpsAndOthersAuxUrl, formData);
        setIsLoading(false);
        if (response?.data?.data?.id) {
          const { id } = response?.data?.data;
          navigate("/cooling-towers");
        } else {
          // alert(`${response.data.message}`);
          toast.error(response.data.message);
        }
      } catch (error) {
        setIsLoading(false);
        // alert("An error occurred while submitting the form.");
        toast.error("Something error occured..!, Please try again.");
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    setIsLoading(true);
    callPostApi(projectDetails, {
      project_id: localStorage.getItem("project_id"),
    })
      .then((res) => {
        let result = res["data"]["data"];
        setFormData(result);
        setIsLoading(false);
      })

      .catch((error) => {
        console.log(error);
      });
  }, []);

  const AuxiliaryData = () => {
    return (
      <>
        <div className="content-page mt-0 class-back-ground border-colo-top">
          <div className="content">
            <div className="container-fluid">
              <div className="row justify-content-center">
                <div className="col-lg-11">
                  <div className="card transparent">
                    <div className="card-body pt-1">
                      <div className="row">
                        <div className="col-lg-12">
                          <div
                            className="page-title-box float-left mr-2"
                            style={{ padding: "40px 0px 30px 0" }}
                          >
                            <h4 className="table-title page-title-underline ">
                              Auxiliaries
                            </h4>
                          </div>
                        </div>
                      </div>
                      <form action="" onSubmit={handleSubmit}>
                        <div
                          className="col-lg-6 col-md-6"
                          style={{ padding: "0px" }}
                        >
                          <div className="form-group">
                            <label className="input_label">
                              {`Other Auxiliaries (${formData?.selected_units}h)`}
                            </label>

                            <input
                              type="number"
                              className="form-control"
                              id="floatingInput"
                              placeholder="Ex.1144"
                              value={formData?.other_auxiliaries_kwh}
                              onChange={handleChange}
                              name="other_auxiliaries_kwh"
                              pattern="[0-9]*"
                            />
                          </div>
                        </div>
                        {/* <div className="row">
                          <div className="col-lg-12 col-md-12 mb-2">
                            <FileUpload formData={formData} fileDocName="auxiliaries_other_document_file" setFormData={setFormData}  />

                          </div>
                        </div> */}

                        <div className="row">
                          <div
                            className="col-lg-6 col-md-6 mb-2"
                            style={{ paddingRight: "0px" }}
                            s
                          >
                            <label className="input_label">
                              Document Upload
                            </label>
                            <div className="form-floating mb-0 ">
                              <input
                                type="file"
                                className="filestyle form-control py-1 px-2 inputclass-name-pd-0"
                                name="auxiliaries_other_document_file"
                                onChange={handleFileUpload}
                                style={{ width: "100%" }}
                              />
                              {formData.auxiliaries_other_document_file ? (
                                <span>
                                  <p
                                    style={{
                                      fontWeight: "bold",
                                      color: "yellow",
                                    }}
                                  >
                                    Previous File: &nbsp;
                                    <a
                                      rel="noreferrer"
                                      style={{ color: "#00d9ff" }}
                                      href={`${BaseURL}${formData?.auxiliaries_other_document_file}`}
                                      target="_blank"
                                    >
                                      Click Here
                                    </a>
                                  </p>
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="form-group row justify-content-end prj">
                          <div className="col-lg-6 col-md-6 col-sm-6 col-6 text-left">
                            <button
                              type="submit"
                              onClick={() => navigate("/pumps")}
                              className="btn-black mr-1 waves-effect waves-light"
                            >
                              <i className="fas fa-angle-left"></i> Back
                            </button>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-6 col-6  text-right">
                            <button
                              type="submit"
                              className="btn-pryellow mr-1 waves-effect waves-light"
                            >
                              Next <i className="fas fa-angle-right"></i>
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <Dashboardsidebar currentPage={"auxaliries"} />
          {AuxiliaryData()}
        </>
      )}
    </>
  );
};

export default Auxiliaries;
