import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useCookies, withCookies } from "react-cookie";
import { toast } from "react-toastify";

import "../css/main.css";
import "../css/login.css";
import "../css/logincustom.css";
import "../css/structure.css";
import { LoginURL } from "../globalURL";
import Spinner from "./project/Spinner";
import callPostApi from "../services/api";

const Login = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [remember, setrememberMe] = useState(false);
  const [usernameError, setUsernameError] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [cookies, setCookie] = useCookies();
  const [isLogin, setIsLogin] = useState(localStorage.getItem("isLogin"));

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    setUsername(() => cookies.username || "");
    setPassword(() => cookies.password || "");
  }, [cookies]);

  useEffect(() => {
    if (!isLogin) {
      navigate("/");
    }
  }, [isLogin, navigate]);

  const handleSubmit = async (event) => {
    console.log("Login Clicked...");
    event.preventDefault();
    setIsLoading(true);
    if (!username) {
      setUsernameError("Please enter username");
      setIsLoading(false);
      return;
    }
    if (username.length < 3) {
      setUsernameError("Please enter valid username");
      setIsLoading(false);
      return;
    }
    if (!password) {
      setPasswordError("Please enter  password");
      setIsLoading(false);
      return;
    } else if (password.length < 6) {
      setPasswordError("Please enter valid password");
      setIsLoading(false);
      return;
    }

    setSubmitted(true);
    console.log("Login URL:" + LoginURL);
    await callPostApi(LoginURL, { username: username, password: password })
      .then((response) => {
        setIsLoading(false);
        localStorage.clear();
        if (response !== undefined) {
          setIsLoading(false);
          if (response?.data?.status === "valid") {
            localStorage.setItem(
              "access_token",
              response["data"]["access_token"]
            );

            localStorage.setItem("isLogin", true);

            if (remember !== false) {
              setCookie("username", username, { path: "/" });
              setCookie("password", password, { path: "/" });
            } else {
              setCookie("username", "", { path: "/" });
              setCookie("password", "", { path: "/" });
            }
            toast.success(response["data"]["message"]);
            navigate("/dashboard");
          } else {
            if (response["data"]["error"] === "email_verification_required") {
              localStorage.setItem("email", response["data"]["data"]["email"]);
              navigate("/otp");
            }
            toast.error(response["data"]["message"]);
          }
        }
      })
      .catch((error) => {
        setSubmitted(false);
        setIsLoading(false);
        console.log(error);
        if (error?.response?.status === 401) {
          if (error["response"]["data"]["error"] === "username_not_found") {
            setUsernameError("User not found. Please Signup.");
            toast.error("User not found. Please Signup.");
          }
          if (error["response"]["data"]["error"] === "invalid_password") {
            setPasswordError("Wrong password.");
            toast.error("Wrong password. Please try again....!");
          }
        }
      });
    setSubmitted(false);
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <div className="login-one" style={{ backgroundColor: "#000" }}>
            <div className="container-fluid login-one-container">
              <div className="p-30 mt-30">
                <div className="row justify-content-center">
                  <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 p-0">
                    <div className="log-bg">
                      <div className="login-one-start">
                        <div className="text-center py-1">
                          <img
                            src="assets/images/logo.png"
                            alt=""
                            height="100"
                          />
                        </div>
                        <p className="text-center txt-yellow mt-4 mb-1 font-16 text-black">
                          Please Login to your account
                        </p>

                        <form action="#" onSubmit={handleSubmit}>
                          <div className="login-one-inputs mt-2">
                            <input
                              type="text"
                              placeholder="Username"
                              value={username}
                              onChange={(event) => {
                                setUsername(event.target.value);
                                setUsernameError("");
                              }}
                            />
                            <span>
                              <i className="las la-user-alt"></i>
                            </span>
                            {usernameError && (
                              <div className="text-danger">{usernameError}</div>
                            )}
                          </div>
                          <div className="login-one-inputs mt-3">
                            <input
                              type={showPassword ? "text" : "password"}
                              placeholder="Password"
                              value={password}
                              onChange={(event) => {
                                setPassword(event.target.value);
                                setPasswordError("");
                              }}
                            />
                            <span>
                              <i className="las la-lock"></i>
                            </span>
                            <span
                              className="password-toggle"
                              onClick={handleTogglePassword}
                            >
                              {showPassword ? (
                                <i className="las la-eye-slash"></i>
                              ) : (
                                <i className="las la-eye"></i>
                              )}
                            </span>
                            {passwordError && (
                              <div className="text-danger">{passwordError}</div>
                            )}
                          </div>
                          <div className="flxreset">
                            <div className="d-flex justify-content-between">
                              <div className="login-one-inputs check mt-3">
                                <input
                                  className="inp-cbx"
                                  id="cbx"
                                  type="checkbox"
                                  style={{ display: "none" }}
                                />
                                <div className="checkbox checkbox-info mb-2 pl-1">
                                  <input
                                    id="remember2"
                                    type="checkbox"
                                    value={true}
                                    onChange={(event) =>
                                      setrememberMe(event.target.checked)
                                    }
                                    data-parsley-multiple="remember2"
                                    checked={remember}
                                  />
                                  <label
                                    htmlFor="remember2"
                                    className="text-black"
                                  >
                                    Remember me ?
                                  </label>
                                </div>
                              </div>
                              <div className="login-one-inputs mt-27 text-right font-12">
                                <Link
                                  to="/forgotpwd"
                                  relative="path"
                                  className="text-black"
                                >
                                  Forgot your Password ?
                                </Link>
                              </div>
                            </div>
                          </div>
                          <div className="login-one-inputs mt-3 text-center">
                            {submitted ? (
                              <button
                                className="ripple-button ripple-button-primary btn-sm btn-login"
                                type="button"
                              >
                                Loading
                                <span className="spinner-grow spinner-grow-sm"></span>
                                <span className="spinner-grow spinner-grow-sm"></span>
                                <span className="spinner-grow spinner-grow-sm"></span>
                              </button>
                            ) : (
                              <button className="btn-login" type="submit">
                                LOGIN
                              </button>
                            )}
                          </div>
                        </form>

                        <div className="login-one-inputs mt-3 text-center font-13">
                          New Here? &nbsp;
                          <Link
                            to="/register"
                            relative="path"
                            style={{ color: "#5b6890", fontWeight: "700" }}
                          >
                            Sign up &nbsp;
                          </Link>
                          and discover more
                        </div>

                        <div className="devlop text-center">
                          <p className="textcolo-delop">Developed by</p>
                          <h6 className="textcolo-delo">
                            {" "}
                            Gulf Organisation for Research & Development
                          </h6>
                          <img src="assets/images/login-logo.png"></img>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default withCookies(Login);
