import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  checkEmailExistsURL,
  userProfileUpdateURL,
  checkUsernameExistsURL,
  citiesListURL,
  countriesListURL,
  passwordChangeUrl,
  statesListURL,
  userProfileURL,
  uploadFileOrImage,
} from "../../globalURL";
import Userdashsidebar from "../Userdashsidebar";

import Select from "react-select";
import Spinner from "../project/Spinner";
import callPostApi from "../../services/api";
const Userprofile = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dataFetchedRef = useRef(false);
  const [formData, setFormData] = useState({
    appleation: "",
    first_name: "",
    last_name: "",
    middle_name: "",
    username: "",
    designation: "",
    profile_image: "",
    country: "",
    // state: "",
    city: "",
    email: "",
    alternatice_email: "",
    mobile: "",

    user_type: "Individual",
    service_provider_name: "",
    service_provider_address: "",
    service_provider_email: "",
    service_provider_phone: "",
    service_provider_website: "",
    service_provider_country: "",
    // service_provider_state: "",
    service_provider_city: "",
    current_password: "",
    new_password: "",
    confirm_password: "",
  });

  const [formDataErrors, setFormDataErrors] = useState({
    appleation: "",
    first_name: "",
    last_name: "",
    middle_name: "",
    username: "",
    designation: "",
    profile_image: "",
    country: "",
    state: "",
    city: "",
    email: "",
    alternatice_email: "",
    mobile: "",

    user_type: "",
    service_provider_name: "",
    service_provider_address: "",
    service_provider_email: "",
    service_provider_phone: "",
    service_provider_website: "",
    service_provider_country: "",
    service_provider_state: "",
    service_provider_city: "",
    current_password: "",
    new_password: "",
    confirm_password: "",
  });

  const [countriesList, setCountriesList] = useState([]);
  const [statesList, setStatesList] = useState("");
  // const [citiesList, setCitiesList] = useState("");
  const [statesListCompany, setStatesListCompany] = useState("");
  const [citiesListCompany, setCitiesListCompany] = useState("");
  const [citiesListService, setCitiesListService] = useState("");

  const [citiesList, setCitiesList] = useState([]);
  const fetchCities = async (id) => {
    await callPostApi(citiesListURL, { country: id, state_id: "" })
      .then((res) => {
        setCitiesList(res["data"]["data"]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // const appleationList = [
  //   { value: "Dr.", label: "Dr" },
  //   { value: "Mr.", label: "Mr" },
  //   { value: "Ms.", label: "Ms" },
  //   { value: "Mrs.", label: "Mrs" },
  // ];

  const appleationList = [
    { id: "1", name: "Mr" },
    { id: "2", name: "Ms" },
    { id: "3", name: "Mrs" },
    { id: "4", name: "Dr" },
  ];

  useEffect(() => {
    callPostApi(countriesListURL, { status: 1 })
      .then((res) => {
        setCountriesList(res["data"]["countires"]);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const fetchStates = async (id) => {
    await callPostApi(statesListURL, { country: id, state_id: "" })
      .then((res) => {
        console.log("stl", res.data);
        setStatesList(res?.data["data"]["data"]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function handleFileUpload(event) {
    const formDataImage = new FormData();
    formDataImage.append("file", event.target.files[0]);
    const headers = { "Content-Type": "multipart/form-data" };
    callPostApi(uploadFileOrImage, formDataImage, headers).then((res) => {
      console.log("pi", res?.data["data"]["full_file_address"]);
      setFormData({
        ...formData,
        ["profile_image"]: res?.data["data"]["full_file_address"],
      });
    });

    // console.log("fileDocName", fileDocName);
  }

  // const fetchCities = async (stateID) => {
  //   await callPostApi(
  //       citiesListURL,
  //       { state_id: stateID: "" })
  //     .then((res) => {
  //       setCitiesList(res["data"]["data"]);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  const fetchUsernameExists = async (username) => {
    if (username < 3) {
    } else {
      await callPostApi(checkUsernameExistsURL, { username: username })
        .then((res) => {
          // console.log("RES:" + res);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const fetchCheckEmailExists = async (email) => {
    if (email < 5) {
      // alert("D")
    } else {
      await callPostApi(checkEmailExistsURL, { email: email })
        .then((res) => {
          // console.log("RES:" + res);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const fetchCitiesService = async (countryID) => {
    await callPostApi(citiesListURL, { country: countryID })
      .then((res) => {
        setCitiesListService(res["data"]["data"]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    let newData = { ...formData };

    if (name === "user_type") {
      newData.user_type = value;
      if (value === "Individual") {
        newData.service_provider_name = "";
        newData.service_provider_address = "";
        newData.service_provider_email = "";
        newData.service_provider_phone = "";
        newData.service_provider_website = "";
        newData.service_provider_country = "";
        newData.service_provider_state = "";
        newData.service_provider_city = "";
      }
    } else {
      newData[name] = value;
    }

    setFormData(newData);

    if (formDataErrors[name] && value) {
      setFormDataErrors({ ...formDataErrors, [name]: "" });
    }
  };

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    setIsLoading(true);
    callPostApi(userProfileURL, {})
      .then((res) => {
        console.log(res["data"]["data"]["data"]);
        let result = res["data"]["data"];
        setFormData(result);
        setIsLoading(false);
        // appleationList.map((a) => {
        //   if (a.value === result["appleation"]) {
        //     setSelectedOptions(a);
        //   }
        // });
        fetchCities(result["country"]);
      })

      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  }, []);

  const handlePasswordChange = async (e) => {
    // alert("PC")
    e.preventDefault();
    const errors = {};
    if (!formData.current_password) {
      errors.current_password = "Please enter password";
    }
    if (!formData.new_password) {
      errors.new_password = "Please new password";
    }
    if (!formData.confirm_password) {
      errors.confirm_password = "Please confirm password";
    }
    if (
      formData.confirm_password &&
      formData.new_password !== formData.confirm_password
    ) {
      errors.new_password = "New & Confirm Passwords must match";
    }

    if (
      formData.new_password === formData.confirm_password &&
      formData.confirm_password &&
      formData.current_password === formData.confirm_password
    ) {
      errors.new_password = "Current & New Passwords same not allowed";
    }

    if (Object.keys(errors).length > 0) {
      setFormDataErrors(errors);
      return;
    }
    // service call here
    await callPostApi(passwordChangeUrl, {
      new_password: formData.new_password,
      current_password: formData.current_password,
      confirm_password: formData.confirm_password,
    })
      .then((res) => {
        if (res.data["status"] === "invalid") {
          // password change failed
          // alert(res.data["message"])
          toast.error(res.data["message"]);
        } else {
          // password change successfully
          // alert(res.data["message"])
          toast.success(res.data["message"]);
        }
      })
      .catch((error) => {
        toast.error("Passwords not matching");
        console.log(error);
        // alert("Passwords not matching");
      });
  };

  const handleSubmit = async (event) => {
    console.log("user data", formData);
    event.preventDefault();
    setIsLoading(true);
    const errors = {};

    if (!formData.appleation) {
      errors.appleation = "Please select appleation";
    }
    if (!formData.first_name) {
      errors.first_name = "Please enter first name";
    }

    if (!formData.country) {
      errors.country = "Please select country";
    }
    if (!formData.state) {
      errors.state = "Please select state";
    }
    if (!formData.city) {
      errors.city = "Please select city";
    }
    if (!formData.mobile) {
      errors.mobile = "Please enter mobile";
    }
    if (!formData.email) {
      errors.email = "Please enter email";
    }

    if (Object.keys(errors).length > 0) {
      setFormDataErrors(errors);
      setIsLoading(false);
    }

    console.log(formData);
    callPostApi(userProfileUpdateURL, formData)
      .then((res) => {
        setIsLoading(false);
        if (res.data.status === "valid") {
          setIsLoading(false);
          toast.success(res["data"]["message"]);
        } else {
          toast.error(res["data"]["message"]);
        }
      })
      .catch((error) => {
        setSubmitted(false);
        setIsLoading(false);
        toast.error("Something error occured..!, Please try again.");
        // console.log("error",error["response"]["data"]);
      });
  };
  const [selectedOptions, setSelectedOptions] = useState();
  // function handleSelect(data) {
  //   setSelectedOptions(data);
  //   console.log(data.value);
  //   setFormDataErrors({ ...formData, ["appleation"]: data.value });
  // }
  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <Userdashsidebar currentPage={"userprofile"} />
          <div className="content-page mt-0 class-back-ground border-colo-top">
            <div className="content">
              <div className="container-fluid">
                <div className="row justify-content-center">
                  <div className="col-lg-12 pl-lg-4 pr-lg-3">
                    <div className="card transparent">
                      <div className="card-body pt-1">
                        <div className="row justify-content-center">
                          <div className="col-lg-12">
                            <div className="page-title-box">
                              <h4
                                className="page-title pb-0"
                                style={{ color: "#3b3f5c" }}
                              >
                                User Profile
                              </h4>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-12 newregisterbox">
                            <form
                              action=""
                              className="registerform"
                              onSubmit={handleSubmit}
                            >
                              <fieldset>
                                <legend>Personal Information</legend>
                                <div className="row">
                                  <div className="col-lg-4 col-md-6 col-sm-6">
                                    <div className="form-group">
                                      <label htmlFor="">Appellation</label>
                                      <span className="text-danger">*</span>
                                      {/* <Select
                                        placeholder="Select Appleation"
                                        onChange={handleSelect}
                                        value={selectedOptions}
                                        options={appleationList}
                                      /> */}

                                      <select
                                        name="appleation"
                                        value={formData.appleation}
                                        onChange={handleInputChange}
                                        className="form-control"
                                      >
                                        <option value="" hidden>
                                          Choose Appellations
                                        </option>
                                        {appleationList &&
                                          appleationList.map((app) => (
                                            <option
                                              key={app.name}
                                              value={app.name}
                                            >
                                              {app.name}
                                            </option>
                                          ))}
                                      </select>
                                      {formDataErrors.appleation && (
                                        <div className="text-danger">
                                          {formDataErrors.appleation}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-4 col-md-6 col-sm-6">
                                    <div className="form-group">
                                      <label htmlFor="">First Name</label>{" "}
                                      <span className="text-danger">*</span>
                                      <input
                                        value={formData.first_name}
                                        name="first_name"
                                        onChange={handleInputChange}
                                        type="text"
                                        className="form-control"
                                        placeholder="Ex. John"
                                      />
                                      {formDataErrors.first_name && (
                                        <div className="text-danger">
                                          {formDataErrors.first_name}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-lg-4 col-md-6 col-sm-6">
                                    <div className="form-group">
                                      <label htmlFor="">MiddleName</label>
                                      <input
                                        value={formData.middle_name}
                                        name="middle_name"
                                        onChange={handleInputChange}
                                        type="text"
                                        className="form-control"
                                        placeholder="Ex.Frc"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-4 col-md-6 col-sm-6">
                                    <div className="form-group">
                                      <label htmlFor="">Last Name</label>
                                      <input
                                        value={formData.last_name}
                                        name="last_name"
                                        onChange={handleInputChange}
                                        type="text"
                                        className="form-control"
                                        placeholder="Doe"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-4 col-md-6 col-sm-6">
                                    <div className="form-group">
                                      <label htmlFor="">Username</label>{" "}
                                      <span className="text-danger">*</span>
                                      <input
                                        value={formData.username}
                                        name="username"
                                        onChange={(event) => {
                                          handleInputChange(event);
                                          fetchUsernameExists(
                                            event.target.value
                                          );
                                        }}
                                        type="text"
                                        className="form-control"
                                        placeholder="Doe John"
                                        readOnly={true}
                                      />
                                      {formDataErrors.username && (
                                        <div className="text-danger">
                                          {formDataErrors.username}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-lg-4 col-md-6 col-sm-6">
                                    <div className="form-group">
                                      <label htmlFor="">Designation</label>
                                      <input
                                        value={formData.designation}
                                        name="designation"
                                        onChange={handleInputChange}
                                        type="text"
                                        className="form-control"
                                        placeholder="Manager"
                                      />
                                    </div>
                                  </div>

                                  <div className="col-lg-4 col-md-6 col-sm-6"></div>
                                  <div className="col-lg-4 col-md-6 col-sm-6">
                                    <div className="form-group">
                                      <label htmlFor="">Upload Image</label>
                                      <input
                                        // value={formData.profile_image}
                                        name="profile_image"
                                        onChange={handleFileUpload}
                                        type="file"
                                        className="form-control inputclass-name-pd-0"
                                        placeholder="Manager"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </fieldset>
                              <fieldset>
                                <legend>Contact Information</legend>
                                <div className="row">
                                  <div className="col-lg-4 col-md-6 col-sm-6">
                                    <div className="form-group">
                                      <label htmlFor="">Country</label>
                                      <span className="text-danger">*</span>
                                      <select
                                        onChange={(event) => {
                                          setFormData({
                                            ...formData,
                                            ["country"]: event.target.value,
                                          });
                                          fetchCities(event.target.value);
                                        }}
                                        className="form-control"
                                        name="country"
                                        value={formData.country}
                                      >
                                        <option value="" hidden>
                                          Select Country
                                        </option>
                                        {countriesList &&
                                          countriesList.map((app) => (
                                            <option
                                              key={app}
                                              value={app}
                                              selected={
                                                app === formData.country
                                              }
                                            >
                                              {app}
                                            </option>
                                          ))}
                                      </select>
                                      {formDataErrors.country && (
                                        <div className="text-danger">
                                          {formDataErrors.country}
                                        </div>
                                      )}
                                    </div>
                                  </div>

                                  <div className="col-lg-4 col-md-6 col-sm-6">
                                    <div className="form-group">
                                      <label htmlFor="">City </label>
                                      <span className="text-danger">*</span>
                                      <select
                                        onChange={handleInputChange}
                                        name="city"
                                        className="form-control"
                                      >
                                        <option value="" hidden>
                                          Select City
                                        </option>
                                        {citiesList &&
                                          citiesList.map((app) => (
                                            <option
                                              key={app}
                                              value={app}
                                              selected={app === formData.city}
                                            >
                                              {app}
                                            </option>
                                          ))}
                                      </select>
                                      {formDataErrors.city && (
                                        <div className="text-danger">
                                          {formDataErrors.city}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-lg-4 col-md-6 col-sm-6">
                                    <div className="form-group">
                                      <label htmlFor="">Email</label>
                                      <span className="text-danger">*</span>
                                      <input
                                        readOnly={true}
                                        value={formData.email}
                                        name="email"
                                        onChange={(event) => {
                                          handleInputChange(event);
                                          fetchCheckEmailExists(
                                            event.target.value
                                          );
                                        }}
                                        type="email"
                                        className="form-control"
                                        placeholder="Email Address"
                                      />
                                      {formDataErrors.email && (
                                        <div className="text-danger">
                                          {formDataErrors.email}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-lg-4 col-md-6 col-sm-6">
                                    <div className="form-group">
                                      <label htmlFor="">
                                        Alternative Email Address
                                      </label>
                                      <input
                                        value={formData.alternatice_email}
                                        name="alternatice_email"
                                        onChange={handleInputChange}
                                        type="text"
                                        className="form-control"
                                        placeholder="Email address"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-4 col-md-6 col-sm-6">
                                    <div className="form-group">
                                      <label htmlFor="">Mobile </label>
                                      <span className="text-danger">*</span>
                                      <input
                                        name="mobile"
                                        value={formData.mobile}
                                        onChange={handleInputChange}
                                        type="text"
                                        className="form-control"
                                        placeholder="1234567890"
                                      />
                                      {formDataErrors.mobile && (
                                        <div className="text-danger">
                                          {formDataErrors.mobile}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </fieldset>
                              <fieldset>
                                <legend>Company Information</legend>
                                <div className="row">
                                  <div className="col-lg-12">
                                    <div className="form-group radiocust">
                                      <div className="row">
                                        <div className="col-lg-2">
                                          <label>
                                            <input
                                              value="Individual"
                                              type="radio"
                                              onChange={handleInputChange}
                                              name="user_type"
                                              checked={
                                                formData.user_type ===
                                                "Individual"
                                              }
                                            />
                                            <span></span>&nbsp;&nbsp; Individual
                                          </label>
                                        </div>
                                        <div className="col-lg-8">
                                          <label>
                                            <input
                                              value="Company"
                                              type="radio"
                                              onChange={handleInputChange}
                                              name="user_type"
                                              checked={
                                                formData.user_type === "Company"
                                              }
                                            />
                                            <span></span>&nbsp;&nbsp; Company
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    className="col-lg-12"
                                    style={{
                                      display:
                                        formData.user_type === "Company"
                                          ? "block"
                                          : "none",
                                    }}
                                  >
                                    <div className="row">
                                      <div className="col-lg-12 col-md-12 col-sm-12">
                                        <div className="form-group">
                                          <label htmlFor="">Company Name</label>
                                          <input
                                            value={
                                              formData.service_provider_name
                                            }
                                            name="service_provider_name"
                                            onChange={handleInputChange}
                                            type="text"
                                            className="form-control"
                                            placeholder="John Doe"
                                          />
                                          {formDataErrors.service_provider_name && (
                                            <div className="text-danger">
                                              {
                                                formDataErrors.service_provider_name
                                              }
                                            </div>
                                          )}
                                        </div>
                                      </div>

                                      <div className="col-lg-12 col-md-12 col-sm-12">
                                        <div className="form-group">
                                          <label htmlFor="">
                                            Company Adress
                                          </label>
                                          <textarea
                                            onChange={handleInputChange}
                                            name="service_provider_address"
                                            rows="3"
                                            className="form-control"
                                            placeholder="Dubai"
                                            defaultValue={
                                              formData.service_provider_address
                                            }
                                          ></textarea>
                                        </div>
                                      </div>
                                      <div className="col-lg-4 col-md-6 col-sm-6">
                                        <div className="form-group">
                                          <label htmlFor="">Email</label>
                                          <input
                                            value={
                                              formData.service_provider_email
                                            }
                                            name="service_provider_email"
                                            onChange={handleInputChange}
                                            type="text"
                                            className="form-control"
                                            placeholder="Email address"
                                          />
                                          {formDataErrors.service_provider_email && (
                                            <div className="text-danger">
                                              {
                                                formDataErrors.service_provider_email
                                              }
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                      <div className="col-lg-4 col-md-6 col-sm-6">
                                        <div className="form-group">
                                          <label htmlFor="">Phone</label>
                                          <input
                                            value={
                                              formData.service_provider_phone
                                            }
                                            name="service_provider_phone"
                                            onChange={handleInputChange}
                                            type="text"
                                            className="form-control"
                                            placeholder="1234567890"
                                          />
                                          {formDataErrors.service_provider_phone && (
                                            <div className="text-danger">
                                              {
                                                formDataErrors.service_provider_phone
                                              }
                                            </div>
                                          )}
                                        </div>
                                      </div>

                                      <div className="col-lg-4 col-md-6 col-sm-6">
                                        <div className="form-group">
                                          <label htmlFor="">Website</label>
                                          <input
                                            value={
                                              formData.service_provider_website
                                            }
                                            name="service_provider_website"
                                            onChange={handleInputChange}
                                            type="text"
                                            className="form-control"
                                            placeholder="http://mywebsite.com"
                                          />
                                          {formDataErrors.service_provider_website && (
                                            <div className="text-danger">
                                              {
                                                formDataErrors.service_provider_website
                                              }
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                      <div className="col-lg-4 col-md-6 col-sm-6">
                                        <div className="form-group">
                                          <label htmlFor="">
                                            Country of Incorporation{" "}
                                          </label>
                                          <select
                                            onChange={(event) => {
                                              setFormData({
                                                ...formData,
                                                ["service_provider_state"]:
                                                  event.target.value,
                                              });
                                              fetchCitiesService(
                                                event.target.value
                                              );
                                            }}
                                            className="form-control"
                                            name="service_provider_state"
                                            value={
                                              formData.service_provider_state
                                            }
                                          >
                                            <option value="" hidden>
                                              Select Country
                                            </option>
                                            {countriesList &&
                                              countriesList.map((app) => (
                                                <option
                                                  key={app}
                                                  value={app}
                                                  selected={
                                                    app ===
                                                    formData.service_provider_state
                                                  }
                                                >
                                                  {app}
                                                </option>
                                              ))}
                                          </select>

                                          {formDataErrors.service_provider_country && (
                                            <div className="text-danger">
                                              {
                                                formDataErrors.service_provider_country
                                              }
                                            </div>
                                          )}
                                        </div>
                                      </div>

                                      <div className="col-lg-4 col-md-6 col-sm-6">
                                        <div className="form-group">
                                          <label htmlFor="">City </label>

                                          <select
                                            name="service_provider_city"
                                            onChange={(event) => {
                                              setFormData({
                                                ...formData,
                                                ["service_provider_city"]:
                                                  event.target.value,
                                              });
                                            }}
                                            className="form-control"
                                            value={
                                              formData.service_provider_city
                                            }
                                          >
                                            <option value="" hidden>
                                              Select City
                                            </option>

                                            {citiesListService &&
                                              citiesListService.map((app) => (
                                                <option
                                                  key={app}
                                                  value={app}
                                                  selected={
                                                    formData.service_provider_city ===
                                                    (app ||
                                                      formData.service_provider_city)
                                                  }
                                                >
                                                  {app}
                                                </option>
                                              ))}
                                          </select>
                                          {formDataErrors.service_provider_city && (
                                            <div className="text-danger">
                                              {
                                                formDataErrors.service_provider_city
                                              }
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </fieldset>
                              <div className="row d-flx justify-content-center mb-2">
                                <div className="col-lg-3 col-md-6 col-sm-6">
                                  <button
                                    type="submit"
                                    className="btn btn-primary mr-2"
                                  >
                                    {" "}
                                    UPDATE
                                  </button>
                                </div>
                              </div>
                            </form>
                            <form
                              action=""
                              className="passwordChangeForm"
                              onSubmit={handlePasswordChange}
                            >
                              <fieldset>
                                <legend>Change Password</legend>
                                <div className="row">
                                  <div className="col-lg-4 col-md-6 col-sm-6">
                                    <div className="form-group">
                                      <label htmlFor="">
                                        Current Password{" "}
                                      </label>{" "}
                                      <span className="text-danger">*</span>
                                      <input
                                        onChange={handleInputChange}
                                        name="current_password"
                                        type="password"
                                        className="form-control"
                                        placeholder="****"
                                      />
                                      <small>6 characters minimum</small>
                                      {formDataErrors.current_password && (
                                        <div className="text-danger">
                                          {formDataErrors.current_password}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-lg-4 col-md-6 col-sm-6">
                                    <div className="form-group">
                                      <label htmlFor="">New Password</label>{" "}
                                      <span className="text-danger">*</span>
                                      <input
                                        value={formData.new_password}
                                        name="new_password"
                                        onChange={handleInputChange}
                                        type="password"
                                        className="form-control"
                                        placeholder="****"
                                      />
                                    </div>
                                    {formDataErrors.new_password && (
                                      <div className="text-danger">
                                        {formDataErrors.new_password}
                                      </div>
                                    )}
                                  </div>
                                  <div className="col-lg-4 col-md-6 col-sm-6">
                                    <div className="form-group">
                                      <label htmlFor="">Confirm Password</label>{" "}
                                      <span className="text-danger">*</span>
                                      <input
                                        value={formData.confirm_password}
                                        name="confirm_password"
                                        onChange={handleInputChange}
                                        type="password"
                                        className="form-control"
                                        placeholder="****"
                                      />
                                    </div>
                                    {formDataErrors.confirm_password && (
                                      <div className="text-danger">
                                        {formDataErrors.confirm_password}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <hr />
                                <div className="row">
                                  <div className="col-lg-3 col-md-6 col-sm-6">
                                    <button
                                      type="submit"
                                      className="btn btn-primary mr-2"
                                    >
                                      {" "}
                                      Change Password{" "}
                                    </button>
                                  </div>
                                </div>
                              </fieldset>
                            </form>
                          </div>
                          {/* <pre style={{ color: "#fff" }}>
                                                {JSON.stringify(countriesList, null, 2)}
                                            </pre> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Userprofile;
