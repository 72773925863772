
import React, { useEffect, useRef, useState } from "react";
import { mainPackages, subPackages, allPackages } from "../../globalURL";
import Userdashsidebar from "../Userdashsidebar";
import { toast } from "react-toastify";
import Spinner from "../project/Spinner";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretRight } from "@fortawesome/free-solid-svg-icons";

const PackagesDesign = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dataFetchedRef = useRef(false);
  const [packagesData, setpackagesData] = useState([]);

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <div>
            <Userdashsidebar currentPage={"packages"} />
            <div className="content-page mt-0 class-back-ground border-colo-top">
              <div className="content">
                <div className="container-fluid">
                  <div className="row justify-content-center">
                    <div className="col-lg-10">
                      <div className="card transparent">
                        <div className="card-body pt-1">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="page-title-box mb-5">
                                <h4
                                  style={{ color: "#FFFF00" }}
                                  className="page-title"
                                >
                                  Arcadia Subscription Plans
                                </h4>
                                <p className="text-white">
                                  Join the growing number of project
                                  stakeholders who have relied on Arcadia Suite.
                                  Choose from our flexible subscription plans
                                  that fit your energy assessment needs and
                                  budget
                                </p>
                              </div>
                            </div>
                          </div>

                          {/* Tabs header */}
                          <ul className="nav nav-tabs mb-3">
                            <li
                              style={{ marginRight: "50px" }}
                              className={`nav-item`}
                            >
                              <Link className={`nav-link active `}>
                                Arcadia Suite Software License
                              </Link>
                            </li>
                            <li className={`nav-item`}>
                              <Link className={`nav-link tabone`}>
                                3rd Party Certification for DC Plant
                              </Link>
                            </li>
                          </ul>

                          <div className="row">
                            <div className="col-lg-6">
                              <div className="table-responsive">
                                <table className="table packages-table">
                                  <h4
                                    style={{
                                      fontWeight: "600",
                                      color: "#FFFF00",
                                    }}
                                    className="text-center mb-4"
                                  >
                                    Basic Package
                                  </h4>
                                  <div className="row d-flex justify-content-center mb-3">
                                    <button
                                      type="button"
                                      style={{
                                        backgroundColor: "#FFFF00",
                                        color: "#000",
                                        fontWeight: "600",
                                      }}
                                      className="btn "
                                    >
                                      BUY NOW
                                    </button>
                                  </div>
                                  <tbody>
                                    <tr>
                                      <td className="text-white with-border-bottom">
                                        Package Title
                                      </td>
                                      <td>:</td>
                                      <td>Air cooled </td>
                                    </tr>

                                    <tr>
                                      <td className="text-white">
                                        Package Details
                                      </td>
                                      <td>:</td>
                                      {/* <td>{packageDetails.desc} </td> */}
                                    </tr>
                                    <tr>
                                      <td className="text-white">
                                        Package Validity
                                      </td>
                                      <td>:</td>
                                      {/* <td>{packageDetails.validity} Months</td> */}
                                    </tr>
                                    <tr>
                                      <td className="text-white">
                                        No. Of Projects Allowed
                                      </td>
                                      <td>:</td>
                                    </tr>
                                    <tr>
                                      <td className="text-white">
                                        Air Cooled Chiller Enable
                                      </td>
                                      <td>:</td>
                                    </tr>
                                    <tr>
                                      <td className="text-white">
                                        Water Cooled Chiller Enable
                                      </td>
                                      <td>:</td>
                                    </tr>
                                    <tr>
                                      <td className="text-white">
                                        Thermal Store Enable
                                      </td>
                                      <td>:</td>
                                      {/* <td>{packageDetails.thermal_store_enable} </td> */}
                                    </tr>
                                    <tr>
                                      <td className="text-white">
                                        With Advisory
                                      </td>
                                      <td>:</td>
                                      {/* <td>{packageDetails.with_advisory} </td> */}
                                    </tr>
                                    <tr>
                                      <td className="text-white">
                                        Package Price
                                      </td>
                                      <td>:</td>
                                      {/* <td>${packageDetails.price} </td> */}
                                    </tr>
                                    <tr>
                                      <td className="text-white">
                                        Advisory Price
                                      </td>
                                      <td>:</td>
                                      {/* <td>${packageDetails.advisory_amount} </td> */}
                                    </tr>

                                    <tr>
                                      <td className="text-white">
                                        Total Payable Amount
                                      </td>
                                      <td>:</td>
                                      {/* <td>${packageDetails.advisory_amount} </td> */}
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>

                            <div className="col-lg-6">
                              <div className="table-responsive">
                                <table className="table packages-table">
                                  <h4
                                    style={{
                                      fontWeight: "600",
                                      color: "#FFFF00",
                                    }}
                                    className="text-center fw-600 mb-4"
                                  >
                                    Premium Package
                                  </h4>
                                  <div className="row d-flex justify-content-center mb-3">
                                    <button
                                      type="button"
                                      style={{
                                        backgroundColor: "#FFFF00",
                                        color: "#000",
                                        fontWeight: "600",
                                      }}
                                      className="btn"
                                    >
                                      BUY NOW
                                    </button>
                                  </div>
                                  <tbody>
                                    <tr>
                                      <td className="text-white">
                                        Package Title
                                      </td>
                                      <td>:</td>
                                      {/* <td>{packageDetails.title} </td> */}
                                    </tr>

                                    <tr>
                                      <td className="text-white">
                                        Package Details
                                      </td>
                                      <td>:</td>
                                      {/* <td>{packageDetails.desc} </td> */}
                                    </tr>
                                    <tr>
                                      <td className="text-white">
                                        Package Validity
                                      </td>
                                      <td>:</td>
                                      {/* <td>{packageDetails.validity} Months</td> */}
                                    </tr>
                                    <tr>
                                      <td className="text-white">
                                        No. Of Projects Allowed
                                      </td>
                                      <td>:</td>
                                      {/* <td>{packageDetails.no_of_projects_allow} Project</td> */}
                                    </tr>
                                    <tr>
                                      <td className="text-white">
                                        Air Cooled Chiller Enable
                                      </td>
                                      <td>:</td>
                                      {/* <td>{packageDetails.air_cooled_chiller_enable} </td> */}
                                    </tr>
                                    <tr>
                                      <td className="text-white">
                                        Water Cooled Chiller Enable
                                      </td>
                                      <td>:</td>
                                      {/* <td>{packageDetails.water_cooled_chiller_enable} </td> */}
                                    </tr>
                                    <tr>
                                      <td className="text-white">
                                        Thermal Store Enable
                                      </td>
                                      <td>:</td>
                                      {/* <td>{packageDetails.thermal_store_enable} </td> */}
                                    </tr>
                                    <tr>
                                      <td className="text-white">
                                        With Advisory
                                      </td>
                                      <td>:</td>
                                      {/* <td>{packageDetails.with_advisory} </td> */}
                                    </tr>
                                    <tr>
                                      <td className="text-white">
                                        Package Price
                                      </td>
                                      <td>:</td>
                                      {/* <td>${packageDetails.price} </td> */}
                                    </tr>
                                    <tr>
                                      <td className="text-white">
                                        Advisory Price
                                      </td>
                                      <td>:</td>
                                      {/* <td>${packageDetails.advisory_amount} </td> */}
                                    </tr>

                                    <tr>
                                      <td className="text-white">
                                        Total Payable Amount
                                      </td>
                                      <td>:</td>
                                      {/* <td>${packageDetails.advisory_amount} </td> */}
                                    </tr>
                                  </tbody>
                                  {/* ${(withAdvisroySelected === "true")?  parseFloat(packageDetails.price) + parseFloat(packageDetails.advisory_amount) :packageDetails.price} */}
                                </table>
                              </div>
                            </div>
                          </div>

                          {/* tabs content starts */}

                          <div className="tab-content">
                            <div
                              style={{
                                borderTop: "1px solid yellow",
                                borderBottom: "1px solid yellow",
                              }}
                              className="col-lg-12 py-2 mb-4"
                            >
                              <Link style={{ color: "#FFF", fontSize: "18px" }}>
                                <div className="row d-flex align-items-center">
                                  <FontAwesomeIcon
                                    style={{
                                      color: "#00ff00",
                                      fontSize: "20px",
                                    }}
                                    icon={faCaretDown}
                                  />
                                  &nbsp; On-site Cooling Plant (2 support
                                  sessions)
                                </div>
                              </Link>
                            </div>

                            <div className="row">
                              <div className="col-lg-6">
                                <div className="table-responsive">
                                  <table className="table packages-table">
                                    <h4
                                      style={{
                                        fontWeight: "600",
                                        color: "#FFFF00",
                                      }}
                                      className="text-center mb-4"
                                    >
                                      Air Cooled Chiller Plant
                                    </h4>
                                    <div className="row d-flex justify-content-center mb-3">
                                      <button
                                        type="button"
                                        style={{
                                          backgroundColor: "#FFFF00",
                                          color: "#000",
                                          fontWeight: "600",
                                        }}
                                        className="btn "
                                      >
                                        BUY NOW
                                      </button>
                                    </div>
                                    <tbody>
                                      <tr>
                                        <td className="text-white with-border-bottom">
                                          Package Title
                                        </td>
                                        <td>:</td>
                                        {/* <td>{packageDetails.title} </td> */}
                                        <td>Air cooled </td>
                                      </tr>

                                      <tr>
                                        <td className="text-white">
                                          Package Details
                                        </td>
                                        <td>:</td>
                                        {/* <td>{packageDetails.desc} </td> */}
                                      </tr>
                                      <tr>
                                        <td className="text-white">
                                          Package Validity
                                        </td>
                                        <td>:</td>
                                        {/* <td>{packageDetails.validity} Months</td> */}
                                      </tr>
                                      <tr>
                                        <td className="text-white">
                                          No. Of Projects Allowed
                                        </td>
                                        <td>:</td>
                                        {/* <td>{packageDetails.no_of_projects_allow} Project</td> */}
                                      </tr>
                                      <tr>
                                        <td className="text-white">
                                          Air Cooled Chiller Enable
                                        </td>
                                        <td>:</td>
                                        {/* <td>{packageDetails.air_cooled_chiller_enable} </td> */}
                                      </tr>
                                      <tr>
                                        <td className="text-white">
                                          Water Cooled Chiller Enable
                                        </td>
                                        <td>:</td>
                                        {/* <td>{packageDetails.water_cooled_chiller_enable} </td> */}
                                      </tr>
                                      <tr>
                                        <td className="text-white">
                                          Thermal Store Enable
                                        </td>
                                        <td>:</td>
                                        {/* <td>{packageDetails.thermal_store_enable} </td> */}
                                      </tr>
                                      <tr>
                                        <td className="text-white">
                                          With Advisory
                                        </td>
                                        <td>:</td>
                                        {/* <td>{packageDetails.with_advisory} </td> */}
                                      </tr>
                                      <tr>
                                        <td className="text-white">
                                          Package Price
                                        </td>
                                        <td>:</td>
                                        {/* <td>${packageDetails.price} </td> */}
                                      </tr>
                                      <tr>
                                        <td className="text-white">
                                          Advisory Price
                                        </td>
                                        <td>:</td>
                                        {/* <td>${packageDetails.advisory_amount} </td> */}
                                      </tr>

                                      <tr>
                                        <td className="text-white">
                                          Total Payable Amount
                                        </td>
                                        <td>:</td>
                                        {/* <td>${packageDetails.advisory_amount} </td> */}
                                      </tr>
                                    </tbody>
                                    {/* ${(withAdvisroySelected === "true")?  parseFloat(packageDetails.price) + parseFloat(packageDetails.advisory_amount) :packageDetails.price} */}
                                  </table>
                                </div>
                              </div>

                              <div className="col-lg-6">
                                <div className="table-responsive">
                                  <table className="table packages-table">
                                    <h4
                                      style={{
                                        fontWeight: "600",
                                        color: "#FFFF00",
                                      }}
                                      className="text-center fw-600 mb-4"
                                    >
                                      Water Cooled Chiller Plant
                                    </h4>
                                    <div className="row d-flex justify-content-center mb-3">
                                      <button
                                        type="button"
                                        style={{
                                          backgroundColor: "#FFFF00",
                                          color: "#000",
                                          fontWeight: "600",
                                        }}
                                        className="btn"
                                      >
                                        BUY NOW
                                      </button>
                                    </div>
                                    <tbody>
                                      <tr>
                                        <td className="text-white">
                                          Package Title
                                        </td>
                                        <td>:</td>
                                        {/* <td>{packageDetails.title} </td> */}
                                      </tr>

                                      <tr>
                                        <td className="text-white">
                                          Package Details
                                        </td>
                                        <td>:</td>
                                        {/* <td>{packageDetails.desc} </td> */}
                                      </tr>
                                      <tr>
                                        <td className="text-white">
                                          Package Validity
                                        </td>
                                        <td>:</td>
                                        {/* <td>{packageDetails.validity} Months</td> */}
                                      </tr>
                                      <tr>
                                        <td className="text-white">
                                          No. Of Projects Allowed
                                        </td>
                                        <td>:</td>
                                        {/* <td>{packageDetails.no_of_projects_allow} Project</td> */}
                                      </tr>
                                      <tr>
                                        <td className="text-white">
                                          Air Cooled Chiller Enable
                                        </td>
                                        <td>:</td>
                                        {/* <td>{packageDetails.air_cooled_chiller_enable} </td> */}
                                      </tr>
                                      <tr>
                                        <td className="text-white">
                                          Water Cooled Chiller Enable
                                        </td>
                                        <td>:</td>
                                        {/* <td>{packageDetails.water_cooled_chiller_enable} </td> */}
                                      </tr>
                                      <tr>
                                        <td className="text-white">
                                          Thermal Store Enable
                                        </td>
                                        <td>:</td>
                                        {/* <td>{packageDetails.thermal_store_enable} </td> */}
                                      </tr>
                                      <tr>
                                        <td className="text-white">
                                          With Advisory
                                        </td>
                                        <td>:</td>
                                        {/* <td>{packageDetails.with_advisory} </td> */}
                                      </tr>
                                      <tr>
                                        <td className="text-white">
                                          Package Price
                                        </td>
                                        <td>:</td>
                                        {/* <td>${packageDetails.price} </td> */}
                                      </tr>
                                      <tr>
                                        <td className="text-white">
                                          Advisory Price
                                        </td>
                                        <td>:</td>
                                        {/* <td>${packageDetails.advisory_amount} </td> */}
                                      </tr>

                                      <tr>
                                        <td className="text-white">
                                          Total Payable Amount
                                        </td>
                                        <td>:</td>
                                        {/* <td>${packageDetails.advisory_amount} </td> */}
                                      </tr>
                                    </tbody>
                                    {/* ${(withAdvisroySelected === "true")?  parseFloat(packageDetails.price) + parseFloat(packageDetails.advisory_amount) :packageDetails.price} */}
                                  </table>
                                </div>
                              </div>
                            </div>

                            <div
                              style={{
                                borderTop: "1px solid yellow",
                                borderBottom: "1px solid yellow",
                              }}
                              className="col-lg-12 py-2 mb-4"
                            >
                              <Link
                                style={{ color: "#FFF", fontSize: "18px" }}
                                className={`tab-pane`}
                              >
                                <div className="row d-flex align-items-center">
                                  <FontAwesomeIcon
                                    style={{
                                      color: "#00ff00",
                                      fontSize: "20px",
                                    }}
                                    icon={faCaretDown}
                                  />
                                  &nbsp; DC Plant(4 support sessions)
                                </div>
                              </Link>
                            </div>

                            <div className="row">
                              <div className="col-lg-6">
                                <div className="table-responsive">
                                  <table className="table packages-table">
                                    <h4
                                      style={{
                                        fontWeight: "600",
                                        color: "#FFFF00",
                                      }}
                                      className="text-center mb-4"
                                    >
                                      &lt; 10,000 TR without TES Tank
                                    </h4>
                                    <div className="row d-flex justify-content-center mb-3">
                                      <button
                                        type="button"
                                        style={{
                                          backgroundColor: "#FFFF00",
                                          color: "#000",
                                          fontWeight: "600",
                                        }}
                                        className="btn "
                                      >
                                        BUY NOW
                                      </button>
                                    </div>
                                    <tbody>
                                      <tr>
                                        <td className="text-white with-border-bottom">
                                          Package Title
                                        </td>
                                        <td>:</td>
                                        {/* <td>{packageDetails.title} </td> */}
                                        <td>Air cooled </td>
                                      </tr>

                                      <tr>
                                        <td className="text-white">
                                          Package Details
                                        </td>
                                        <td>:</td>
                                        {/* <td>{packageDetails.desc} </td> */}
                                      </tr>
                                      <tr>
                                        <td className="text-white">
                                          Package Validity
                                        </td>
                                        <td>:</td>
                                        {/* <td>{packageDetails.validity} Months</td> */}
                                      </tr>
                                      <tr>
                                        <td className="text-white">
                                          No. Of Projects Allowed
                                        </td>
                                        <td>:</td>
                                        {/* <td>{packageDetails.no_of_projects_allow} Project</td> */}
                                      </tr>
                                      <tr>
                                        <td className="text-white">
                                          Air Cooled Chiller Enable
                                        </td>
                                        <td>:</td>
                                        {/* <td>{packageDetails.air_cooled_chiller_enable} </td> */}
                                      </tr>
                                      <tr>
                                        <td className="text-white">
                                          Water Cooled Chiller Enable
                                        </td>
                                        <td>:</td>
                                        {/* <td>{packageDetails.water_cooled_chiller_enable} </td> */}
                                      </tr>
                                      <tr>
                                        <td className="text-white">
                                          Thermal Store Enable
                                        </td>
                                        <td>:</td>
                                        {/* <td>{packageDetails.thermal_store_enable} </td> */}
                                      </tr>
                                      <tr>
                                        <td className="text-white">
                                          With Advisory
                                        </td>
                                        <td>:</td>
                                        {/* <td>{packageDetails.with_advisory} </td> */}
                                      </tr>
                                      <tr>
                                        <td className="text-white">
                                          Package Price
                                        </td>
                                        <td>:</td>
                                        {/* <td>${packageDetails.price} </td> */}
                                      </tr>
                                      <tr>
                                        <td className="text-white">
                                          Advisory Price
                                        </td>
                                        <td>:</td>
                                        {/* <td>${packageDetails.advisory_amount} </td> */}
                                      </tr>

                                      <tr>
                                        <td className="text-white">
                                          Total Payable Amount
                                        </td>
                                        <td>:</td>
                                        {/* <td>${packageDetails.advisory_amount} </td> */}
                                      </tr>
                                    </tbody>
                                    {/* ${(withAdvisroySelected === "true")?  parseFloat(packageDetails.price) + parseFloat(packageDetails.advisory_amount) :packageDetails.price} */}
                                  </table>
                                </div>
                              </div>

                              <div className="col-lg-6">
                                <div className="table-responsive">
                                  <table className="table packages-table">
                                    <h4
                                      style={{
                                        fontWeight: "600",
                                        color: "#FFFF00",
                                      }}
                                      className="text-center fw-600 mb-4"
                                    >
                                      &gt; 10,000 TR Without TES Tank
                                    </h4>
                                    <div className="row d-flex justify-content-center mb-3">
                                      <button
                                        type="button"
                                        style={{
                                          backgroundColor: "#FFFF00",
                                          color: "#000",
                                          fontWeight: "600",
                                        }}
                                        className="btn"
                                      >
                                        BUY NOW
                                      </button>
                                    </div>
                                    <tbody>
                                      <tr>
                                        <td className="text-white">
                                          Package Title
                                        </td>
                                        <td>:</td>
                                        {/* <td>{packageDetails.title} </td> */}
                                      </tr>

                                      <tr>
                                        <td className="text-white">
                                          Package Details
                                        </td>
                                        <td>:</td>
                                        {/* <td>{packageDetails.desc} </td> */}
                                      </tr>
                                      <tr>
                                        <td className="text-white">
                                          Package Validity
                                        </td>
                                        <td>:</td>
                                        {/* <td>{packageDetails.validity} Months</td> */}
                                      </tr>
                                      <tr>
                                        <td className="text-white">
                                          No. Of Projects Allowed
                                        </td>
                                        <td>:</td>
                                        {/* <td>{packageDetails.no_of_projects_allow} Project</td> */}
                                      </tr>
                                      <tr>
                                        <td className="text-white">
                                          Air Cooled Chiller Enable
                                        </td>
                                        <td>:</td>
                                        {/* <td>{packageDetails.air_cooled_chiller_enable} </td> */}
                                      </tr>
                                      <tr>
                                        <td className="text-white">
                                          Water Cooled Chiller Enable
                                        </td>
                                        <td>:</td>
                                        {/* <td>{packageDetails.water_cooled_chiller_enable} </td> */}
                                      </tr>
                                      <tr>
                                        <td className="text-white">
                                          Thermal Store Enable
                                        </td>
                                        <td>:</td>
                                        {/* <td>{packageDetails.thermal_store_enable} </td> */}
                                      </tr>
                                      <tr>
                                        <td className="text-white">
                                          With Advisory
                                        </td>
                                        <td>:</td>
                                        {/* <td>{packageDetails.with_advisory} </td> */}
                                      </tr>
                                      <tr>
                                        <td className="text-white">
                                          Package Price
                                        </td>
                                        <td>:</td>
                                        {/* <td>${packageDetails.price} </td> */}
                                      </tr>
                                      <tr>
                                        <td className="text-white">
                                          Advisory Price
                                        </td>
                                        <td>:</td>
                                        {/* <td>${packageDetails.advisory_amount} </td> */}
                                      </tr>

                                      <tr>
                                        <td className="text-white">
                                          Total Payable Amount
                                        </td>
                                        <td>:</td>
                                        {/* <td>${packageDetails.advisory_amount} </td> */}
                                      </tr>
                                    </tbody>
                                    {/* ${(withAdvisroySelected === "true")?  parseFloat(packageDetails.price) + parseFloat(packageDetails.advisory_amount) :packageDetails.price} */}
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="tab-underline"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PackagesDesign;
