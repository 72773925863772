import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { Link } from "react-router-dom";

const Anchormenu = () => {
  return (
    <div className="m-auto">
      <ul className="navbar-nav">
        <li className="nav-item">
          <AnchorLink href="#home" className="nav-link">
            Home
          </AnchorLink>
        </li>
        <li className="nav-item">
          <AnchorLink href="#about" className="nav-link">
            About
          </AnchorLink>
        </li>
        <li className="nav-item">
          <AnchorLink href="#features" className="nav-link">
            Features
          </AnchorLink>
        </li>
        <li className="nav-item">
          <AnchorLink href="#projects" className="nav-link">
            Projects
          </AnchorLink>
        </li>
        <li className="nav-item">
          <AnchorLink href="#3rdparty" className="nav-link">
            3<sup>rd</sup> Party Certification
          </AnchorLink>
        </li>
        <li className="nav-item">
          <AnchorLink href="#subscriptions" className="nav-link">
            Subscriptions
          </AnchorLink>
        </li>

        <li className="nav-item">
          <AnchorLink href="#contact" className="nav-link">
            Contact
          </AnchorLink>
        </li>

        <li className="nav-item">
          <Link to="/login" className="nav-link">
            Login
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Anchormenu;
