import axios from "axios";

const callPostApi = async (
  url,
  postData = {},
  headers = { "Content-Type": "application/json" }
) => {
  try {
    let urlData = postData;
    if (!urlData?.access_token) {
      urlData.access_token = localStorage.getItem("access_token");
    }
    const response = await axios.post(url, urlData, { headers });
    if (response.status === 200) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
  }
};

export const callGetApi = async (
  url,
  getParamsData = {},
  headers = { "Content-Type": "application/json" }
) => {
  try {
    let urlData = getParamsData;
    if (!urlData?.access_token) {
      urlData.access_token = localStorage.getItem("access_token");
    }
    const response = await axios.get(url, urlData, { headers });
    return response;
  } catch (error) {
    console.log(error);
  }
};

export default callPostApi;
