import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Dashboardsidebar from "./Dashboardsidebar";
import {
  BaseURL,
  coolingUrl,
  projectDetails,
  uploadFileOrImage,
} from "../../globalURL";
import Spinner from "./Spinner";
import { toast } from "react-toastify";
import "../../css/background_image.css";
import callPostApi from "../../services/api";

const CoolingTower = () => {
  const dataFetchedRef = useRef(false);
  const navigate = useNavigate();
  const [showFans, setShowFans] = useState(false);
  const [showFCT, setShowFCT] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const initialFormData = {
    cooling_tower_other_document_file: "",
    wet_bulb_temp_1: "",
    wet_bulb_temp_2: "",
    wet_bulb_temp_3: "",
    wet_bulb_temp_4: "",
    wet_bulb_temp_5: "",
    wet_bulb_temp_6: "",
    wet_bulb_temp_7: "",
    wet_bulb_temp_8: "",
    wet_bulb_temp_9: "",
    wet_bulb_temp_10: "",
    corresponding_leaving_water_temp_1: "",
    corresponding_leaving_water_temp_2: "",
    corresponding_leaving_water_temp_3: "",
    corresponding_leaving_water_temp_4: "",
    corresponding_leaving_water_temp_5: "",
    corresponding_leaving_water_temp_6: "",
    corresponding_leaving_water_temp_7: "",
    corresponding_leaving_water_temp_8: "",
    corresponding_leaving_water_temp_9: "",
    corresponding_leaving_water_temp_10: "",
    // "fan1_type": "Variable",
    // "fan1_power_load_100": "",
    // "fan1_power_load_75": "",
    // "fan1_power_load_50": "",
    // "fan1_power_load_25": "",
    // "fan1_power_input_100": "",
    // "fan1_power_input_75": "",
    // "fan1_power_input_50": "",
    // "fan1_power_input_25": "",
    // "fan2_type": "Variable",
    // "fan2_power_load_100": "",
    // "fan2_power_load_75": "",
    // "fan2_power_load_50": "",
    // "fan2_power_load_25": "",
    // "fan2_power_input_100": "",
    // "fan2_power_input_75": "",
    // "fan2_power_input_50": "",
    // "fan2_power_input_25": "",
    // "fan3_type": "Variable",
    // "fan3_power_load_100": "",
    // "fan3_power_load_75": "",
    // "fan3_power_load_50": "",
    // "fan3_power_load_25": "",
    // "fan3_power_input_100": "",
    // "fan3_power_input_75": "",
    // "fan3_power_input_50": "",
    // "fan3_power_input_25": "",
    // "fan4_type": "Variable",
    // "fan4_power_load_100": "",
    // "fan4_power_load_75": "",
    // "fan4_power_load_50": "",
    // "fan4_power_load_25": "",
    // "fan4_power_input_100": "",
    // "fan4_power_input_75": "",
    // "fan4_power_input_50": "",
    // "fan4_power_input_25": "",
    // "fan5_type": "Variable",
    // "fan5_power_load_100": "",
    // "fan5_power_load_75": "",
    // "fan5_power_load_50": "",
    // "fan5_power_load_25": "",
    // "fan5_power_input_100": "",
    // "fan5_power_input_75": "",
    // "fan5_power_input_50": "",
    // "fan5_power_input_25": "",
    // "fan6_type": "Variable",
    // "fan6_power_load_100": "",
    // "fan6_power_load_75": "",
    // "fan6_power_load_50": "",
    // "fan6_power_load_25": "",
    // "fan6_power_input_100": "",
    // "fan6_power_input_75": "",
    // "fan6_power_input_50": "",
    // "fan6_power_input_25": "",
    // "fan7_type": "Variable",
    // "fan7_power_load_100": "",
    // "fan7_power_load_75": "",
    // "fan7_power_load_50": "",
    // "fan7_power_load_25": "",
    // "fan7_power_input_100": "",
    // "fan7_power_input_75": "",
    // "fan7_power_input_50": "",
    // "fan7_power_input_25": "",
    // "fan8_type": "Variable",
    // "fan8_power_load_100": "",
    // "fan8_power_load_75": "",
    // "fan8_power_load_50": "",
    // "fan8_power_load_25": "",
    // "fan8_power_input_100": "",
    // "fan8_power_input_75": "",
    // "fan8_power_input_50": "",
    // "fan8_power_input_25": "",
    // "fan9_type": "Variable",
    // "fan9_power_load_100": "",
    // "fan9_power_load_75": "",
    // "fan9_power_load_50": "",
    // "fan9_power_load_25": "",
    // "fan9_power_input_100": "",
    // "fan9_power_input_75": "",
    // "fan9_power_input_50": "",
    // "fan9_power_input_25": "",
    // "fan10_type": "Variable",
    // "fan10_power_load_100": "",
    // "fan10_power_load_75": "",
    // "fan10_power_load_50": "",
    // "fan10_power_load_25": "",
    // "fan10_power_input_100": "",
    // "fan10_power_input_75": "",
    // "fan10_power_input_50": "",
    // "fan10_power_input_25": "",
    _id: localStorage.getItem("project_id"),
  };

  const [formData, setFormData] = useState(initialFormData);

  const wetBulbfieldNames = [
    "wet_bulb_temp_1",
    "wet_bulb_temp_2",
    "wet_bulb_temp_3",
    "wet_bulb_temp_4",
    "wet_bulb_temp_5",
    "wet_bulb_temp_6",
    "wet_bulb_temp_7",
    "wet_bulb_temp_8",
    "wet_bulb_temp_9",
    "wet_bulb_temp_10",
  ];

  const leavingWaterfieldNames = [
    "corresponding_leaving_water_temp_1",
    "corresponding_leaving_water_temp_2",
    "corresponding_leaving_water_temp_3",
    "corresponding_leaving_water_temp_4",
    "corresponding_leaving_water_temp_5",
    "corresponding_leaving_water_temp_6",
    "corresponding_leaving_water_temp_7",
    "corresponding_leaving_water_temp_8",
    "corresponding_leaving_water_temp_9",
    "corresponding_leaving_water_temp_10",
  ];

  function handleFileUpload(event) {
    const formDataImage = new FormData();
    formDataImage.append("file", event.target.files[0]);
    const headers = { "Content-Type": "multipart/form-data" };
    callPostApi(uploadFileOrImage, formDataImage, headers).then((response) => {
      // console.log("sss",data["data"]["full_file_address"]);
      setFormData({
        ...formData,
        ["cooling_tower_other_document_file"]:
          response?.data["data"]["full_file_address"],
      });
    });

    // console.log("fileDocName", fileDocName);
  }

  const handleFans = () => {
    setShowFans(true);
    setShowFCT(false);
  };

  const handleFCT = () => {
    setShowFCT(true);
    setShowFans(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await callPostApi(coolingUrl, formData);
      setIsLoading(false);
      if (response?.data?.data?.id) {
        const { id } = response?.data?.data;
        navigate("/fans");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("Something error occured..!, Please try again.");
      console.log(error);
    }
  };
  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    setIsLoading(true);
    callPostApi(projectDetails, {
      project_id: localStorage.getItem("project_id"),
    })
      .then((res) => {
        let result = res["data"]["data"];
        setFormData(result);
        setIsLoading(false);
      })

      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const fansProps = {
    formData,
    setFormData,
    showFans,
    showFCT,
    setShowFCT,
    setShowFans,
    handleFans,
    handleFCT,
    handleChange,
    handleSubmit,
  };

  const CoolingTowerData = () => {
    return (
      <>
        <div className="content-page mt-0 class-back-ground border-colo-top">
          <div className="content">
            <div className="container-fluid">
              <div className="row justify-content-center">
                <div className="col-lg-11">
                  <div className="card transparent">
                    <div className="card-body pt-1">
                      {/* <div className="row">
                        <div className="col-lg-12">
                          <div className="page-title-box float-left mr-2">
                            <h4 className="page-title">
                              <Link
                                onClick={handleFCT}
                              >
                                Cooling Tower
                              </Link>
                            </h4>
                          </div>
                          <div className="page-title-box">
                            <h4 className="page-title">
                              <Link
                                onClick={handleFans}
                              >
                                Fans
                              </Link>
                            </h4>
                          </div>
                        </div>
                      </div> */}
                      <div className="row">
                        <div className="col-lg-12">
                          <div
                            className="page-title-box float-left mr-2"
                            style={{ padding: "40px 0px 0px 0" }}
                          >
                            <h4 className="table-title page-title-underline">
                              Cooling Tower
                            </h4>
                          </div>
                        </div>
                      </div>
                      {formData.condenser_cooling_media === "Air-cooled" ? (
                        <>
                          <div className="alert alert-success text-center">
                            <h3>
                              <b>Cooling Tower</b> Data Not Applicable to "Air
                              Cooled Chiller"{" "}
                            </h3>
                          </div>
                          <div className="row justify-content-between align-items-center prj">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-6 text-left">
                              <Link
                                to={"/auxiliaries"}
                                className=" btn-black mr-1 waves-effect waves-light"
                              >
                                <i className="fas fa-angle-left"></i> Back
                              </Link>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-6 text-right">
                              <Link
                                className="btn-pryellow mr-1 waves-effect waves-light"
                                to="/co-efficients"
                              >
                                Skip <i className="fas fa-angle-right"></i>
                              </Link>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <form method="POST" onSubmit={handleSubmit}>
                            <div className="row">
                              <div className="col-lg-6 col-md-6">
                                <div className="row">
                                  <div className="col-lg-12 towercool">
                                    <table className="table">
                                      <thead>
                                        <tr>
                                          <th></th>
                                          <th>
                                            <h4
                                              className="table-title page-sub-title-underline"
                                              style={{ justifySelf: "center" }}
                                            >
                                              Wet Bulb Temperature ({" "}
                                              <sup>0</sup>C)
                                            </h4>
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {wetBulbfieldNames.map(
                                          (fieldName, index) => (
                                            <tr key={index}>
                                              <td style={{ width: "140px" }}>
                                                <label
                                                  style={{ color: "black" }}
                                                >
                                                  Cooling Tower {index + 1}
                                                </label>
                                              </td>
                                              <td>
                                                <div className="form-floating">
                                                  <input
                                                    type="number"
                                                    onChange={handleChange}
                                                    value={formData[fieldName]}
                                                    pattern="[0-9]*"
                                                    className="form-control"
                                                    id={fieldName}
                                                    name={fieldName}
                                                    placeholder="Ex.1144"
                                                    style={{
                                                      paddingTop: ".6rem",
                                                    }}
                                                  />
                                                  {/* <label htmlFor={fieldName}>
                                                    Temperature
                                                  </label> */}
                                                </div>
                                              </td>
                                            </tr>
                                          )
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-6 col-md-6">
                                <div className="row">
                                  <div className="col-lg-12 towercool">
                                    <table className="table">
                                      <thead>
                                        <tr>
                                          <th>
                                            <h4
                                              className="table-title page-sub-title-underline"
                                              style={{ justifySelf: "center" }}
                                            >
                                              Corresponding Leaving Temperature
                                              (<sup>0</sup>C)
                                            </h4>
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {leavingWaterfieldNames.map(
                                          (fieldName, index) => (
                                            <tr key={index}>
                                              <td>
                                                <div className="form-floating">
                                                  <input
                                                    type="number"
                                                    onChange={handleChange}
                                                    value={formData[fieldName]}
                                                    pattern="[0-9]*"
                                                    className="form-control"
                                                    id={fieldName}
                                                    name={fieldName}
                                                    placeholder="Ex.1144"
                                                    style={{
                                                      paddingTop: ".6rem",
                                                    }}
                                                  />
                                                  {/* <label htmlFor={fieldName}>
                                                    Temperature
                                                  </label> */}
                                                </div>
                                              </td>
                                              <td
                                                style={{ width: "140px" }}
                                              ></td>
                                            </tr>
                                          )
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* <div className="row">
                              <div className="col-lg-12 col-md-12 mb-2">

                                <FileUpload formData={formData} fileDocName="cooling_tower_other_document_file" setFormData={setFormData}  />

                              </div>
                            </div> */}
                            <div className="row">
                              <div className="col-lg-12 col-md-12 mb-2">
                                <label className="colyellow py-1">
                                  <span
                                    style={{
                                      color: "#000000",
                                      fontWeight: "700",
                                    }}
                                    className="pl-2 pb-2 mb-2"
                                  >
                                    Document Upload
                                  </span>
                                </label>
                                <div className="form-floating mb-0">
                                  <input
                                    type="file"
                                    className="filestyle form-control py-1 px-2 inputclass-name-pd-0"
                                    name="cooling_tower_other_document_file"
                                    onChange={handleFileUpload}
                                  />
                                  {formData.cooling_tower_other_document_file ? (
                                    <span>
                                      <p
                                        style={{
                                          fontWeight: "bold",
                                          color: "yellow",
                                        }}
                                      >
                                        Previous File: &nbsp;
                                        <a
                                          rel="noreferrer"
                                          style={{ color: "#00d9ff" }}
                                          href={`${BaseURL}${formData?.cooling_tower_other_document_file}`}
                                          target="_blank"
                                        >
                                          Click Here
                                        </a>
                                      </p>
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="form-group row justify-content-end prj">
                              <div className="col-lg-6 col-md-6 col-sm-6 col-6 text-left">
                                <button
                                  type="submit"
                                  onClick={() => navigate("/auxiliaries")}
                                  className="btn-black mr-1 waves-effect waves-light"
                                >
                                  <i className="fas fa-angle-left"></i> Back
                                </button>
                              </div>
                              <div className="col-lg-6 col-md-6 col-sm-6 col-6 text-right">
                                <button
                                  type="submit"
                                  className="btn-pryellow mr-1 waves-effect waves-light"
                                >
                                  Next <i className="fas fa-angle-right"></i>
                                </button>
                              </div>
                            </div>
                          </form>
                        </>
                      )}
                    </div>
                    {/* <pre style={{ color: "#fff" }}>
                    {JSON.stringify(formData, null, 2)}
                  </pre> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <Dashboardsidebar currentPage={"cooling-towers"} />
          {CoolingTowerData()}
        </>
      )}
      {/* {showFans ? <Fans handleSubmit={handleSubmit} embedded={true} {...fansProps} /> : CoolingTowerData()} */}
    </>
  );
};

export default CoolingTower;
