import React from "react";
import LoadingGif from "../../images/arcadia_processing.gif";

const Spinner2 = () => {
  return (
    <>
      <div className="overlay-spinner2">
        <div className="overlay-spinner-in">
          <img
            src={LoadingGif}
            alt="loadingspinner"
            style={{ height: "180px" }}
          />
          <p style={{ marginLeft: "50px" }}>
            Please wait , this may take a while .
          </p>
        </div>
      </div>
    </>
  );
};

export default Spinner2;
